import React, { useState, useEffect } from "react";

/**
 * Define Label handles the logic to turn default Player 1 and Player 2 labelling into more descriptive
 * You / Your Opponent labelling for form elements. 
 * @param {string} player - the Player 1 or Player 2 value from Players.js
 * @returns {boolean} true/false - true if Player 1, false if Player 2
 */
export default function DefineLabel({ player }) {
    // Sort unique labelling for you or opponent
    const [you, setYou] = useState(false);
    
    useEffect(() => {
      if (player === 'Player 1') {
        setYou(true)
      }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
      {you ? 'You' : 'Opponent'}
      </>
    );
}