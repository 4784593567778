import React, { useState } from 'react';
import { sendPasswordResetEmail, getAuth } from 'firebase/auth';
import Button from '@mui/material/Button';

export function ResetPassword(val) {
    let [passwordReset, setPasswordReset] = useState(false);
    let [email, setEmail] = useState(val.email);
    const Reset = () => {
        const auth = getAuth();
        if (val.val === "Forgot Password") {
            setEmail(val.email);
        } else {
            setEmail(auth.currentUser.email);
        }
        
        sendPasswordResetEmail(auth, email)
            .then(() => {
                // Password reset email sent!
                // ..
                console.log('Reset email sent');
                setPasswordReset(true);
            })
            .catch((error) => {
                
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('reset error', errorCode, errorMessage);
                // ..
            });

            // disabled={passwordReset ? false : true}
            // {passwordReset ? "Reset Password" : "Password has been reset"}
    }
 
    return (                                             
        <Button className='reset-password' onClick={Reset} value="Forgot Password" variant="outlined" size="small" aria-label="Forgot password">{passwordReset ? "Reset Email Sent" : val.val}</Button>                                   
    )
}