import './sass/App.scss';
import React, { useEffect } from "react";
import { ErrorBoundary } from 'react-error-boundary'
import { Register } from './new_game/create_game/Register';
import { useStickyState } from './new_game/utils/useStickyState';
import { Routes, Route, NavLink } from 'react-router-dom';
import {
  Home,
  Changelog,
} from './pages';
import NotFound from './NotFound';
import { Signup } from './auth/Signup';
import { Login } from './auth/Login';
import PrivacyPolicy from './auth/PrivacyPolicy';
import { ManageAccount } from './auth/ManageAccount';
import { Scoresheets } from './new_game/scoresheets/Scoresheets';
import { GameEnded } from './new_game/GameEnded';
import { StatsCenter } from './stats/StatsCenter';
import { NavBar } from './nav/NavBar';
import TwitterIcon from '@mui/icons-material/Twitter';
import Button from '@mui/material/Button';
import moment from 'moment';
import LogRocket from 'logrocket';
import { createTheme } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { MyFallbackComponent } from './utils/FallbackComponent';

const theme = createTheme();

const useStyles = makeStyles((theme) => {
  root: {
    // some CSS that accesses the theme
  }
});

LogRocket.init('elhrbq/scorehammer');

function App() {
  const [uid, setUid] = useStickyState('Game UID', 'Game UID');
  const classes = useStyles();
  // This function looks to see if uid is in default state (Game UID) and changes it to a random string. 
  // If uid is already a string, it does nothing. 
  // This means that the same unique game url can be stored across multiple device refreshes and enables users to share a specific game state. 
  GenRandom();

  function GenRandom() {
    useEffect(() => {
      if (uid === 'Game UID') {
        const generateRandomString = moment().format() + '_' + Math.random().toString(36).slice(2);
        setUid(generateRandomString);
      } else {
        return;
      }
    }, [])
  }

  return (
    <ThemeProvider theme={theme}>
    <div className="App">
        <header className="App-header">
            <h1> Welcome to Scorehammer</h1>
            <NavBar uniqueUrl={ uid } />
        </header>
        <section>

            <Routes>
                <Route path="/" element={<Home uniqueUrl={ uid } />} />
                <Route path="/changelog" element={<Changelog uniqueUrl={ uid } />} />
                <Route path={ "/new-game/age-of-sigmar-" + uid } element={
                    <ErrorBoundary
                        FallbackComponent={MyFallbackComponent}
                        onReset={() => {
                            // reset the state of your app here
                        }}
                        resetKeys={['someKey']}
                    >
                        <Register uniqueUrl={ uid } />
                    </ErrorBoundary>
                } />
                <Route path={ "/new-game/age-of-sigmar-" + uid + "/game"} element={
                    <ErrorBoundary
                        FallbackComponent={MyFallbackComponent}
                        onReset={() => {
                            // reset the state of your app here
                        }}
                        resetKeys={['someKey']}
                    >
                        <Scoresheets uniqueUrl={ uid + "/game" } />
                    </ErrorBoundary>
                } />
                <Route path={ "ended"} element={<GameEnded uniqueUrl={ uid  } />} />
                <Route path="/signup" element={<Signup/>}/>
                <Route path="/login" element={<Login referrer="/"/>}/>
                <Route path="/my-account" element={<ManageAccount />}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
                <Route path="/stats" element={<StatsCenter />}/>
                <Route path='*' element={<NotFound />}/>
            </Routes>
    
        </section>

        <header className="App-footer">
            <small>v1.0.3 - Last updated: 24/7/23</small>
            <NavLink to={ "/changelog"}>
                <Button value="Changelog" variant="contained" size="small" aria-label="Changelog" color="primary">Changelog</Button>
            </NavLink>
            <NavLink to={ "https://twitter.com/Scorehammer_app" }>
                <Button value="Scorehammer_app" variant="contained" size="small" aria-label="Scorehammer_app" color="info" endIcon={<TwitterIcon />}>Scorehammer_app</Button>
            </NavLink>
        </header>
    </div>
    </ThemeProvider>
  );
}

export default App;
