// Delete me when you delete data console log. 
import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TurnOpen from "./TurnOpen";
import TotalOpen from "./TotalOpen";

const ScoresheetOpen = ({ player, uurl, defaultData }) => {
    let playerName = player.replace(/\s+/g,'').toLowerCase(); 
    const playerFaction = defaultData[playerName].faction;
    const playerSubFaction = defaultData[playerName].subfaction;
    const playerAlliance = [defaultData[playerName].alliance];
    return (
        <Typography component="div">
            <div className="scoresheet scoresheet-open">
                <div className="scoresheet-header">
                    <p><strong>{player}:</strong> {playerFaction}</p>
                    <p><strong>{playerAlliance}:</strong> {playerFaction} - {playerSubFaction}</p>
                </div>

                <TableContainer component={Paper} className="mobileScoresheet">
                    <Table sx={{ minWidth: 320 }} size="small" aria-label="spanning table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="center" size="small">Turn</TableCell>
                            <TableCell align="center" size="small">Victory Points</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <TurnOpen player={player} uurl={uurl} defaultData={defaultData} turn='1' />
                            <TurnOpen player={player} uurl={uurl} defaultData={defaultData} turn='2' />
                            <TurnOpen player={player} uurl={uurl} defaultData={defaultData} turn='3' />
                            <TurnOpen player={player} uurl={uurl} defaultData={defaultData} turn='4' />
                            <TurnOpen player={player} uurl={uurl} defaultData={defaultData} turn='5' />
                        </TableBody>
                    </Table>
                </TableContainer>

                <div className="scoresheet-footer">
                    <div>
                        <TotalOpen player={player} faction={playerFaction} alliance={playerAlliance} uurl={uurl} defaultData={defaultData} />
                    </div>
                </div>
            </div>
        </Typography>    
    )

}
export default ScoresheetOpen;

