import { db } from '../../firebase';
import { setDoc, getDoc, doc, increment } from "firebase/firestore"; 

export function sendData(gameData, player1Name, p1Registered, player2Name, p2Registered, test, uniqueUrlTrimmed) {
    console.log('sendData.js => uniqueUrlTrimmed: ', uniqueUrlTrimmed, gameData.uniqueId);
    // Define Actual or Test DB references added and assigned values. 
    let statsRef;
    if (test) {
        player1Name = "Ross_test";
        player2Name = "Ross_test";
        statsRef = "test";
        console.log('Test Data: ', test, statsRef, player1Name, player2Name);
    } else {
        statsRef = "stats";
        console.log('Real Data: ', test, statsRef, player1Name, player2Name);
    }

    // Define empty tactics arrays for later replacement IF the gameType = Matched Play
    let player1TacticsArray = [];
    let player2TacticsArray = [];

    // Configure Battle Tactic references based on Game Type Selected
    let bTacs1;
    let gStrats1;
    let factionRef1 = doc(db, "usernames", player1Name, 'games', gameData.gameType, "Faction", gameData.player1.alliance);

    let bTacs2;
    let gStrats2;
    let factionRef2 = doc(db, "usernames", player2Name, 'games', gameData.gameType, "Faction", gameData.player2.alliance);

    if (gameData.gameType === "Matched Play") {
        bTacs1 = doc(db, "usernames", player1Name, 'games', gameData.gameType, "Battle Tactics", "Dead");
        gStrats1 = doc(db, "usernames", player1Name, 'games', gameData.gameType, "Grand Strategies", "Dead");
    
        bTacs2 = doc(db, "usernames", player2Name, 'games', gameData.gameType, "Battle Tactics", "Dead");
        gStrats2 = doc(db, "usernames", player2Name, 'games', gameData.gameType, "Grand Strategies", "Dead");

        // Update BattleTactics Array for the sake of Matched Play Games only. 
        for (const [key, value] of Object.entries(gameData.player1.battletactics)) {
            if (value.chosen !== "") {
                player1TacticsArray.push([key, [value.chosen, value.achieved === true ? value.achieved : false]]);
            } 

            // console.log('SendData.js => player1TacticsArray', player1TacticsArray);
        }

        for (const [key, value] of Object.entries(gameData.player2.battletactics)) {
            if (value.chosen !== "") {
                player2TacticsArray.push([key, [value.chosen, value.achieved === true ? value.achieved : false]]);
            }

            // console.log('SendData.js => player2TacticsArray', player2TacticsArray);
        }
    } 

    const playerData = async () => {
        console.log('SendData.js => playerData: ', p1Registered, player1Name);
        // Update player information
        if (p1Registered) {
            console.log('SendData.js => if p1Registered: ', p1Registered, player1Name);
            // Registering Collections
            const docRef = doc(db, "usernames", player1Name, 'games', gameData.gameType);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
            } else {
                // Set Placeholder for Matched Play
                await setDoc(docRef, {
                    gamesPlayed: 0,
                });

                // Set Placeholder Collection For Battle Tactics, Grand Strategies, Mission and Faction
                await setDoc(bTacs1, { placeholder: 0 });
                await setDoc(gStrats1, { placeholder: 0 });
                await setDoc(factionRef1, { gamesPlayed: 0 });
            }

            await setDoc(doc(db, "usernames", player1Name, 'games', gameData.gameType), {
                turnsCompleted: increment(gameData.turnEndedOn),
                gamesPlayed: increment(1),
                wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),

                [gameData.date]: {
                    turnsCompleted: increment(gameData.turnEndedOn),
                    gamesPlayed: increment(1),
                    wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                    losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                    draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                },

            }, { merge: true });

            await setDoc(factionRef1, {
                title: gameData.player1.alliance,
                turnsCompleted: increment(gameData.turnEndedOn),
                gamesPlayed: increment(1),
                wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                [gameData.date]: {
                    title: gameData.player1.alliance,
                    turnsCompleted: increment(gameData.turnEndedOn),
                    gamesPlayed: increment(1),
                    wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                    losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                    draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                },
                mission: {
                    [gameData.mission]: {
                        turnsCompleted: increment(gameData.turnEndedOn),
                        gamesPlayed: increment(1),
                        wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                        losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                        draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                    }
                },
                [gameData.player1.faction]: {
                    title: gameData.player1.faction,
                    turnsCompleted: increment(gameData.turnEndedOn),
                    gamesPlayed: increment(1),
                    wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                    losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                    draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                    [gameData.date]: {
                        title: gameData.player1.faction,
                        turnsCompleted: increment(gameData.turnEndedOn),
                        gamesPlayed: increment(1),
                        wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                        losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                        draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                    },
                    mission: {
                        [gameData.mission]: {
                            turnsCompleted: increment(gameData.turnEndedOn),
                            gamesPlayed: increment(1),
                            wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                            losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                            draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                        }
                    },
                    [gameData.player1.subfaction]: {
                        title: gameData.player1.subfaction,
                        turnsCompleted: increment(gameData.turnEndedOn),
                        gamesPlayed: increment(1),
                        wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                        losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                        draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                        [gameData.date]: {
                            title: gameData.player1.subfaction,
                            turnsCompleted: increment(gameData.turnEndedOn),
                            gamesPlayed: increment(1),
                            wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                            losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                            draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                        },
                        mission: {
                            [gameData.mission]: {
                                turnsCompleted: increment(gameData.turnEndedOn),
                                gamesPlayed: increment(1),
                                wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                                losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                                draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                            }
                        }
                    }
                }
            }, { merge: true });
            if (gameData.gameType === "Matched Play") {
                // Loop through battle tactics and submit
                for(const turn of player1TacticsArray) {
                    // turn[1][1] ? increment(1) : increment(0) allows us to increment or not increment based on if a tactic has been achieved or not. 
                    const playerTacticsRef = doc(db, "usernames", player1Name, 'games', gameData.gameType, 'Battle Tactics', turn[1][0]);
                    // If tactic is achieved
                    await setDoc(playerTacticsRef, {
                        taken: increment(1),
                        achieved: turn[1][1] ? increment(1) : increment(0),
                        [turn[0]]: increment(1),
                        [gameData.date]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            [turn[0]]: increment(1),
                        },
                        byAlliance: {
                            [gameData.player1.alliance]: {
                                title: gameData.player1.alliance,
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                turnTaken: {
                                    [turn[0]]: increment(1),
                                },
                                byFaction: {
                                    [gameData.player1.faction]: {
                                        title: gameData.player1.faction,
                                        taken: increment(1),
                                        achieved: turn[1][1] ? increment(1) : increment(0),
                                        turnTaken: {
                                            [turn[0]]: increment(1),
                                        },
                                        bySubFaction: {
                                            [gameData.player1.subfaction]: {
                                                title: gameData.player1.subfaction,
                                                taken: increment(1),
                                                achieved: turn[1][1] ? increment(1) : increment(0),
                                                turnTaken: {
                                                    [turn[0]]: increment(1),
                                                },
                                            }
                                        },
                                    }
                                },
                            }
                        },
                        mission: {
                            [gameData.mission]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                turnTaken: {
                                    [turn[0]]: increment(1),
                                },
                            }
                        },
                        opponent: {
                            [gameData.player2.alliance]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                turnTaken: {
                                    [turn[0]]: increment(1),
                                },
                                [gameData.player2.faction]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    turnTaken: {
                                        [turn[0]]: increment(1),
                                    },
                                    [gameData.player2.subfaction]: {
                                        taken: increment(1),
                                        achieved: turn[1][1] ? increment(1) : increment(0),
                                        turnTaken: {
                                            [turn[0]]: increment(1),
                                        },
                                    }
                                }
                            }
                        }
                    }, { merge: true });
                }
                // Check for Grand Strategy and submit
                if (gameData.player1.grandstrategy.chosen !== "None") {
                    await setDoc(doc(db, "usernames", player1Name, 'games', gameData.gameType, "Grand Strategies", gameData.player1.grandstrategy.chosen), {
                        title: gameData.player1.grandstrategy.chosen,
                        taken: increment(1),
                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                        byAlliance: {
                            [gameData.player1.alliance]: {
                                title: gameData.player1.alliance,
                                taken: increment(1),
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                byFaction: {
                                    [gameData.player1.faction]: {
                                        title: gameData.player1.faction,
                                        taken: increment(1),
                                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                        bySubFaction: {
                                            [gameData.player1.subfaction]: {
                                                title: gameData.player1.subfaction,
                                                taken: increment(1),
                                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                            }
                                        },
                                    }
                                },
                            }
                        },
                        mission: {
                            [gameData.mission]: {
                                taken: increment(1),
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                            }
                        },
                        opponent: {
                            [gameData.player2.alliance]: {
                                taken: increment(1),
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                [gameData.player2.faction]: {
                                    taken: increment(1),
                                    achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                    [gameData.player2.subfaction]: {
                                        taken: increment(1),
                                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                    }
                                }
                            }
                        }
                    }, { merge: true });
                }
            // End If Statement
            } 
            console.log('SendData.js => if before sending game data: ', p1Registered, player1Name, gameData.uniqueId);
            await setDoc(doc(db, "usernames", player1Name, 'games', gameData.uniqueId), {
                player1Username: gameData.player1.name,
                player2Username: p2Registered ? gameData.player2.name : "None",
                player1: {
                    score: {
                        t1: gameData.player1.score.t1,
                        t2: gameData.player1.score.t2,
                        t3: gameData.player1.score.t3,
                        t4: gameData.player1.score.t4,
                        t5: gameData.player1.score.t5,
                        total: gameData.player1.score.total,
                    },
                    battletactics: {
                        t1: {
                            chosen: gameData.player1.battletactics.t1.chosen,
                            achieved: gameData.player1.battletactics.t1.achieved,
                        },
                        t2: {
                            chosen: gameData.player1.battletactics.t2.chosen,
                            achieved: gameData.player1.battletactics.t2.achieved,
                        },
                        t3: {
                            chosen: gameData.player1.battletactics.t3.chosen,
                            achieved: gameData.player1.battletactics.t3.achieved,
                        },
                        t4: {
                            chosen: gameData.player1.battletactics.t4.chosen,
                            achieved: gameData.player1.battletactics.t4.achieved,
                        },
                        t5: {
                            chosen: gameData.player1.battletactics.t5.chosen,
                            achieved: gameData.player1.battletactics.t5.achieved,
                        },
                    },
                    grandstrategy: {
                        chosen: gameData.player1.grandstrategy.chosen,
                        achieved: gameData.player1.grandstrategy.achieved,
                    },
                    alliance: gameData.player1.alliance,
                    faction: gameData.player1.faction,
                    subfaction: gameData.player1.subfaction,
                    subfactionTitle: gameData.player1.subfactionTitle,
                    name: gameData.player1.name,
                    username: gameData.player1.name,
                },
                player2: {
                    score: {
                        t1: gameData.player2.score.t1,
                        t2: gameData.player2.score.t2,
                        t3: gameData.player2.score.t3,
                        t4: gameData.player2.score.t4,
                        t5: gameData.player2.score.t5,
                        total: gameData.player2.score.total,
                    },
                    battletactics: {
                        t1: {
                            chosen: gameData.player2.battletactics.t1.chosen,
                            achieved: gameData.player2.battletactics.t1.achieved,
                        },
                        t2: {
                            chosen: gameData.player2.battletactics.t2.chosen,
                            achieved: gameData.player2.battletactics.t2.achieved,
                        },
                        t3: {
                            chosen: gameData.player2.battletactics.t3.chosen,
                            achieved: gameData.player2.battletactics.t3.achieved,
                        },
                        t4: {
                            chosen: gameData.player2.battletactics.t4.chosen,
                            achieved: gameData.player2.battletactics.t4.achieved,
                        },
                        t5: {
                            chosen: gameData.player2.battletactics.t5.chosen,
                            achieved: gameData.player2.battletactics.t5.achieved,
                        },
                    },
                    grandstrategy: {
                        chosen: gameData.player2.grandstrategy.chosen,
                        achieved: gameData.player2.grandstrategy.achieved,
                    },
                    alliance: gameData.player2.alliance,
                    faction: gameData.player2.faction,
                    subfaction: gameData.player2.subfaction,
                    subfactionTitle: gameData.player2.subfactionTitle,
                    name: gameData.player2.name,
                    username: p2Registered ? gameData.player2.name : "None",
                },
                complete: true,
                gameType: gameData.gameType,
                mission: gameData.mission,
                season: gameData.season,
                timestamp: gameData.timestamp,
                region: gameData.region,
                turnEndedOn: gameData.turnEndedOn,
                priority: {
                    t1: gameData.priority.t1,
                    t2: gameData.priority.t2,
                    t3: gameData.priority.t3,
                    t4: gameData.priority.t4,
                    t5: gameData.priority.t5,
                },
                loser: gameData.loser,
                losingAlliance: gameData.losingAlliance,
                losingFaction: gameData.losingFaction,
                losingSubFaction: gameData.losingSubFaction,
                winner: gameData.winner,
                winningAlliance: gameData.winningAlliance,
                winningFaction: gameData.winningFaction,
                winningSubFaction: gameData.winningSubFaction,
            }, { merge: true });

        }

        if (p2Registered) {
            // Registering Collections
            const docRef = doc(db, "usernames", player1Name, 'games', gameData.gameType);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
            } else {
                // Set Placeholder for Matched Play
                await setDoc(docRef, {
                    gamesPlayed: 0,
                });

                // Set Placeholder Collection For Battle Tactics, Grand Strategies, Mission and Faction
                await setDoc(bTacs2, { placeholder: 0 });
                await setDoc(gStrats2, { placeholder: 0 });
                await setDoc(factionRef2, { gamesPlayed: 0 });
            }



            await setDoc(doc(db, "usernames", player2Name, 'games', gameData.gameType), {
                turnsCompleted: increment(gameData.turnEndedOn),
                gamesPlayed: increment(1),
                wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                [gameData.date]: {
                    turnsCompleted: increment(gameData.turnEndedOn),
                    gamesPlayed: increment(1),
                    wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                    losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                    draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                },

            }, { merge: true });

            await setDoc(factionRef2, {
                title: gameData.player2.alliance,
                turnsCompleted: increment(gameData.turnEndedOn),
                gamesPlayed: increment(1),
                wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                [gameData.date]: {
                    title: gameData.player2.alliance,
                    turnsCompleted: increment(gameData.turnEndedOn),
                    gamesPlayed: increment(1),
                    wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                    losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                    draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                },
                mission: {
                    [gameData.mission]: {
                        turnsCompleted: increment(gameData.turnEndedOn),
                        gamesPlayed: increment(1),
                        wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                        losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                        draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                    }
                },
                [gameData.player2.faction]: {
                    title: gameData.player2.faction,
                    turnsCompleted: increment(gameData.turnEndedOn),
                    gamesPlayed: increment(1),
                    wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                    losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                    draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                    [gameData.date]: {
                        title: gameData.player2.faction,
                        turnsCompleted: increment(gameData.turnEndedOn),
                        gamesPlayed: increment(1),
                        wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                        losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                        draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                    },
                    mission: {
                        [gameData.mission]: {
                            turnsCompleted: increment(gameData.turnEndedOn),
                            gamesPlayed: increment(1),
                            wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                            losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                            draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                        }
                    },
                    [gameData.player2.subfaction]: {
                        title: gameData.player2.subfaction,
                        turnsCompleted: increment(gameData.turnEndedOn),
                        gamesPlayed: increment(1),
                        wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                        losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                        draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                        [gameData.date]: {
                            title: gameData.player2.subfaction,
                            turnsCompleted: increment(gameData.turnEndedOn),
                            gamesPlayed: increment(1),
                            wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                            losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                            draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                        },
                        mission: {
                            [gameData.mission]: {
                                turnsCompleted: increment(gameData.turnEndedOn),
                                gamesPlayed: increment(1),
                                wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                                losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                                draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                            }
                        }
                    }
                }
            }, { merge: true });

            if (gameData.gameType === "Matched Play") {
                // Loop through battle tactics and submit
                for(const turn of player2TacticsArray) {

                    // turn[1][1] ? increment(1) : increment(0) allows us to increment or not increment based on if a tactic has been achieved or not. 
                    const playerTacticsRef = doc(db, "usernames", player2Name, 'games', gameData.gameType, 'Battle Tactics', turn[1][0]);
                    // If tactic is achieved
                    await setDoc(playerTacticsRef, {
                        title: turn[1][0],
                        taken: increment(1),
                        achieved: turn[1][1] ? increment(1) : increment(0),
                        [gameData.date]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            [turn[0]]: increment(1),
                        },
                        [turn[0]]: increment(1),
                        byAlliance: {
                            [gameData.player2.alliance]: {
                                title: gameData.player2.alliance,
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                turnTaken: {
                                    [turn[0]]: increment(1),
                                },
                                byFaction: {
                                    [gameData.player2.faction]: {
                                        title: gameData.player2.faction,
                                        taken: increment(1),
                                        achieved: turn[1][1] ? increment(1) : increment(0),
                                        turnTaken: {
                                            [turn[0]]: increment(1),
                                        },
                                        bySubFaction: {
                                            [gameData.player2.subfaction]: {
                                                title: gameData.player2.subfaction,
                                                taken: increment(1),
                                                achieved: turn[1][1] ? increment(1) : increment(0),
                                                turnTaken: {
                                                    [turn[0]]: increment(1),
                                                },
                                            }
                                        },
                                    }
                                },
                            }
                        },
                        mission: {
                            [gameData.mission]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                turnTaken: {
                                    [turn[0]]: increment(1),
                                },
                            }
                        },
                        opponent: {
                            [gameData.player1.alliance]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                turnTaken: {
                                    [turn[0]]: increment(1),
                                },
                                [gameData.player1.faction]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    turnTaken: {
                                        [turn[0]]: increment(1),
                                    },
                                    [gameData.player1.subfaction]: {
                                        taken: increment(1),
                                        achieved: turn[1][1] ? increment(1) : increment(0),
                                        turnTaken: {
                                            [turn[0]]: increment(1),
                                        },
                                    }
                                }
                            }
                        }
                    }, { merge: true });
                }
                
                // Check for Grand Strategy and submit
                if (gameData.player2.grandstrategy.chosen !== "None") {
                    await setDoc(doc(db, "usernames", player2Name, 'games', gameData.gameType, "Grand Strategies", gameData.player2.grandstrategy.chosen), {
                        title: gameData.player2.grandstrategy.chosen,
                        taken: increment(1),
                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                        byAlliance: {
                            [gameData.player2.alliance]: {
                                title: gameData.player2.alliance,
                                taken: increment(1),
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                byFaction: {
                                    [gameData.player2.faction]: {
                                        title: gameData.player2.faction,
                                        taken: increment(1),
                                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                        bySubFaction: {
                                            [gameData.player2.subfaction]: {
                                                title: gameData.player2.subfaction,
                                                taken: increment(1),
                                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                            }
                                        },
                                    }
                                },
                            }
                        },
                        mission: {
                            [gameData.mission]: {
                                taken: increment(1),
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                            }
                        },
                        opponent: {
                            [gameData.player1.alliance]: {
                                taken: increment(1),
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                [gameData.player1.faction]: {
                                    taken: increment(1),
                                    achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                    [gameData.player1.subfaction]: {
                                        taken: increment(1),
                                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                    }
                                }
                            }
                        }
                    }, { merge: true });
                }
            // End If
            }

            await setDoc(doc(db, "usernames", player2Name, 'games', gameData.uniqueId), {
                player1Username: p1Registered ? gameData.player1.name : "None",
                player2Username: gameData.player2.name,
                player1: {
                    score: {
                        t1: gameData.player1.score.t1,
                        t2: gameData.player1.score.t2,
                        t3: gameData.player1.score.t3,
                        t4: gameData.player1.score.t4,
                        t5: gameData.player1.score.t5,
                        total: gameData.player1.score.total,
                    },
                    battletactics: {
                        t1: {
                            chosen: gameData.player1.battletactics.t1.chosen,
                            achieved: gameData.player1.battletactics.t1.achieved,
                        },
                        t2: {
                            chosen: gameData.player1.battletactics.t2.chosen,
                            achieved: gameData.player1.battletactics.t2.achieved,
                        },
                        t3: {
                            chosen: gameData.player1.battletactics.t3.chosen,
                            achieved: gameData.player1.battletactics.t3.achieved,
                        },
                        t4: {
                            chosen: gameData.player1.battletactics.t4.chosen,
                            achieved: gameData.player1.battletactics.t4.achieved,
                        },
                        t5: {
                            chosen: gameData.player1.battletactics.t5.chosen,
                            achieved: gameData.player1.battletactics.t5.achieved,
                        },
                    },
                    grandstrategy: {
                        chosen: gameData.player1.grandstrategy.chosen,
                        achieved: gameData.player1.grandstrategy.achieved,
                    },
                    alliance: gameData.player1.alliance,
                    faction: gameData.player1.faction,
                    subfaction: gameData.player1.subfaction,
                    subfactionTitle: gameData.player1.subfactionTitle,
                    username: p1Registered ? gameData.player1.name : "None",
                },
                player2: {
                    score: {
                        t1: gameData.player2.score.t1,
                        t2: gameData.player2.score.t2,
                        t3: gameData.player2.score.t3,
                        t4: gameData.player2.score.t4,
                        t5: gameData.player2.score.t5,
                        total: gameData.player2.score.total,
                    },
                    battletactics: {
                        t1: {
                            chosen: gameData.player2.battletactics.t1.chosen,
                            achieved: gameData.player2.battletactics.t1.achieved,
                        },
                        t2: {
                            chosen: gameData.player2.battletactics.t2.chosen,
                            achieved: gameData.player2.battletactics.t2.achieved,
                        },
                        t3: {
                            chosen: gameData.player2.battletactics.t3.chosen,
                            achieved: gameData.player2.battletactics.t3.achieved,
                        },
                        t4: {
                            chosen: gameData.player2.battletactics.t4.chosen,
                            achieved: gameData.player2.battletactics.t4.achieved,
                        },
                        t5: {
                            chosen: gameData.player2.battletactics.t5.chosen,
                            achieved: gameData.player2.battletactics.t5.achieved,
                        },
                    },
                    grandstrategy: {
                        chosen: gameData.player2.grandstrategy.chosen,
                        achieved: gameData.player2.grandstrategy.achieved,
                    },
                    alliance: gameData.player2.alliance,
                    faction: gameData.player2.faction,
                    subfaction: gameData.player2.subfaction,
                    subfactionTitle: gameData.player2.subfactionTitle,
                    username: gameData.player2.name,
                },
                complete: true,
                gameType: gameData.gameType,
                mission: gameData.mission,
                season: gameData.season,
                timestamp: gameData.timestamp,
                region: gameData.region,
                turnEndedOn: gameData.turnEndedOn,
                priority: {
                    t1: gameData.priority.t1,
                    t2: gameData.priority.t2,
                    t3: gameData.priority.t3,
                    t4: gameData.priority.t4,
                    t5: gameData.priority.t5,
                },
                loser: gameData.loser,
                losingAlliance: gameData.losingAlliance,
                losingFaction: gameData.losingFaction,
                losingSubFaction: gameData.losingSubFaction,
                winner: gameData.winner,
                winningAlliance: gameData.winningAlliance,
                winningFaction: gameData.winningFaction,
                winningSubFaction: gameData.winningSubFaction,
            }, { merge: true });
        }
    }

    // Stringify Game Type
    let gameType;
    if (gameData.gameType === "Matched Play") {
        gameType = "matched";
    } else if (gameData.gameType === "Open Play") {
        gameType = "open";
    } else {
        gameType = "narrative";
    }

    console.log('Send Data: ', gameType);

    const setGameType = async () => {
        await setDoc(doc(db, statsRef, gameType), {
            turnsCompleted: increment(gameData.turnEndedOn),
            gamesPlayed: increment(1),
            date: {
                [gameData.date]: {
                    gamesPlayed: increment(1),
                    turnsCompleted: increment(gameData.turnEndedOn),
                }
            },
            region: {
                [gameData.region.label]: {
                    gamesPlayed: increment(1),
                    turnsCompleted: increment(gameData.turnEndedOn),
                }
            },
            mission: {
                [gameData.mission]: {
                    turnsCompleted: increment(gameData.turnEndedOn),
                    gamesPlayed: increment(1),
                    date: {
                        [gameData.date]: {
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                    region: {
                        [gameData.region.label]: {
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                }
            }
        }, { merge: true });
    }

    const setPoints = async () => {
        // Overall Points
        await setDoc(doc(db, statsRef, gameType, "points", gameData.points), {
            turnsCompleted: increment(gameData.turnEndedOn),
            gamesPlayed: increment(1),
            date: {
                [gameData.date]: {
                    gamesPlayed: increment(1),
                    turnsCompleted: increment(gameData.turnEndedOn),
                }
            },
            region: {
                [gameData.region.label]: {
                    gamesPlayed: increment(1),
                    turnsCompleted: increment(gameData.turnEndedOn),
                }
            },
            mission: {
                [gameData.mission]: {
                    turnsCompleted: increment(gameData.turnEndedOn),
                    gamesPlayed: increment(1),
                    date: {
                        [gameData.date]: {
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                    region: {
                        [gameData.region.label]: {
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                }
            }
        }, { merge: true });
    }

    const setPlayer1Faction = async () => {

        const player1FactionRef = doc(db, statsRef, gameType, "faction", gameData.player1.alliance, gameData.player1.faction, "Wins");
        
        await setDoc(doc(db, statsRef, gameType, "faction", gameData.player1.alliance), {
            gamesPlayed: increment(1),
            turnsCompleted: increment(gameData.turnEndedOn),
            wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
            losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
            draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
        }, { merge: true });

        await setDoc(doc(db, statsRef, gameType, "faction", gameData.player1.alliance, gameData.player1.faction, "Overall"), {
            gamesPlayed: increment(1),
            turnsCompleted: increment(gameData.turnEndedOn),
            wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
            losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
            draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
        }, { merge: true });

        await setDoc(player1FactionRef, {
            wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
            gamesPlayed: increment(1),
            turnsCompleted: increment(gameData.turnEndedOn),
            date: {
                [gameData.date]: {
                    wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                    gamesPlayed: increment(1),
                    turnsCompleted: increment(gameData.turnEndedOn),
                }
            },
            region: {
                [gameData.region.label]: {
                    wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                    gamesPlayed: increment(1),
                    turnsCompleted: increment(gameData.turnEndedOn),
                }
            },
            mission: {
                [gameData.mission]: {
                    wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                    turnsCompleted: increment(gameData.turnEndedOn),
                    gamesPlayed: increment(1),
                    date: {
                        [gameData.date]: {
                            wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                    region: {
                        [gameData.region.label]: {
                            wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                }
            },
            points: {
                [gameData.points]: {
                    wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                    turnsCompleted: increment(gameData.turnEndedOn),
                    gamesPlayed: increment(1),
                    date: {
                        [gameData.date]: {
                            wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                    region: {
                        [gameData.region.label]: {
                            wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                    mission: {
                        [gameData.mission]: {
                            wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                            turnsCompleted: increment(gameData.turnEndedOn),
                            gamesPlayed: increment(1),
                            date: {
                                [gameData.date]: {
                                    wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                                    gamesPlayed: increment(1),
                                    turnsCompleted: increment(gameData.turnEndedOn),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                                    gamesPlayed: increment(1),
                                    turnsCompleted: increment(gameData.turnEndedOn),
                                }
                            },
                        },
                    }
                }
            },
            opponent: { 
                [gameData.player2.alliance]: {
                    wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                    turnsCompleted: increment(gameData.turnEndedOn),
                    gamesPlayed: increment(1),
                    date: {
                        [gameData.date]: {
                            wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                    region: {
                        [gameData.region.label]: {
                            wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                    [gameData.player2.faction]: {
                        wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                        turnsCompleted: increment(gameData.turnEndedOn),
                        gamesPlayed: increment(1),
                        date: {
                            [gameData.date]: {
                                wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                                gamesPlayed: increment(1),
                                turnsCompleted: increment(gameData.turnEndedOn),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                                gamesPlayed: increment(1),
                                turnsCompleted: increment(gameData.turnEndedOn),
                            }
                        },
                        [gameData.player2.subfaction]: {
                            wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                            turnsCompleted: increment(gameData.turnEndedOn),
                            gamesPlayed: increment(1),
                            date: {
                                [gameData.date]: {
                                    wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                                    gamesPlayed: increment(1),
                                    turnsCompleted: increment(gameData.turnEndedOn),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                                    gamesPlayed: increment(1),
                                    turnsCompleted: increment(gameData.turnEndedOn),
                                }
                            },
                        }
                    }
                }
            }
        }, { merge: true });
    }

    const setPlayer1SubFaction = async () => {
        const player1SubFactionRef = doc(db, statsRef, gameType, "faction", gameData.player1.alliance, gameData.player1.faction, gameData.player1.subfaction);
        await setDoc(player1SubFactionRef, {
            wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
            losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
            draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
            gamesPlayed: increment(1),
            turnsCompleted: increment(gameData.turnEndedOn),
            date: {
                [gameData.date]: {
                    wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                    losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                    draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                    gamesPlayed: increment(1),
                    turnsCompleted: increment(gameData.turnEndedOn),
                }
            },
            region: {
                [gameData.region.label]: {
                    wins: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                    losses: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                    draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                    gamesPlayed: increment(1),
                    turnsCompleted: increment(gameData.turnEndedOn),
                }
            },
        }, { merge: true });
    }

    const setPlayer1StratAlliance = async () => {
        // Grand Strategies
        const player1StratRef = doc(db, statsRef, gameType, "faction", gameData.player1.alliance, "Grand Strategies", gameData.player1.grandstrategy.chosen);

        // Increment achieved only if true
        await setDoc(player1StratRef, {
            achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
            takenCount: increment(1),
            date: {
                [gameData.date]: {
                    achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                    takenCount: increment(1),
                }
            },
            region: {
                [gameData.region.label]: {
                    achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                    takenCount: increment(1),
                }
            },
            mission: {
                [gameData.mission]: {
                    [gameData.player1.grandstrategy.chosen]: {
                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                        date: {
                            [gameData.date]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                    },
                }
            },
            points: {
                [gameData.points]: {
                    [gameData.player1.grandstrategy.chosen]: {
                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                        date: {
                            [gameData.date]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                    },
                    mission: {
                        [gameData.mission]: {
                            [gameData.player1.grandstrategy.chosen]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                                date: {
                                    [gameData.date]: {
                                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                                region: {
                                    [gameData.region.label]: {
                                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                            }
                        }
                    }
                }
            },
            opponent: {
                [gameData.player2.alliance]: {
                    [gameData.player1.grandstrategy.chosen]: {
                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                        date: {
                            [gameData.date]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                    },
                    [gameData.player2.faction]: {
                        [gameData.player1.grandstrategy.chosen]: {
                            achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                            takenCount: increment(1),
                            date: {
                                [gameData.date]: {
                                    achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                    takenCount: increment(1),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                    takenCount: increment(1),
                                }
                            },
                        },
                        [gameData.player2.subfaction]: {
                            [gameData.player1.grandstrategy.chosen]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                                date: {
                                    [gameData.date]: {
                                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                                region: {
                                    [gameData.region.label]: {
                                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                            },
                        }
                    }
                }
            }
        }, { merge: true });
    }

    const setPlayer1StratFaction = async () => {
        // Grand Strategies
        const player1StratRef = doc(db, statsRef, gameType, "faction", gameData.player1.alliance, gameData.player1.faction, "Grand Strategy");
        await setDoc(player1StratRef, {
            [gameData.player1.grandstrategy.chosen]: {
                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                takenCount: increment(1),
                date: {
                    [gameData.date]: {
                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                    }
                },
                region: {
                    [gameData.region.label]: {
                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                    }
                },
            },
            mission: {
                [gameData.mission]: {
                    [gameData.player1.grandstrategy.chosen]: {
                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                        date: {
                            [gameData.date]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                    },
                }
            },
            points: {
                [gameData.points]: {
                    [gameData.player1.grandstrategy.chosen]: {
                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                        date: {
                            [gameData.date]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                    },
                    mission: {
                        [gameData.mission]: {
                            [gameData.player1.grandstrategy.chosen]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                                date: {
                                    [gameData.date]: {
                                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                                region: {
                                    [gameData.region.label]: {
                                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                            },
                        }
                    }
                }
            },
            opponent: {
                [gameData.player2.alliance]: {
                    [gameData.player1.grandstrategy.chosen]: {
                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                        date: {
                            [gameData.date]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                    },
                    [gameData.player2.faction]: {
                        [gameData.player1.grandstrategy.chosen]: {
                            achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                            takenCount: increment(1),
                            date: {
                                [gameData.date]: {
                                    achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                    takenCount: increment(1),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                    takenCount: increment(1),
                                }
                            },
                        },
                        [gameData.player2.subfaction]: {
                            [gameData.player1.grandstrategy.chosen]: {
                                achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                                date: {
                                    [gameData.date]: {
                                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                                region: {
                                    [gameData.region.label]: {
                                        achieved: gameData.player1.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                            },
                        }
                    }
                }
            }
        }, { merge: true });
    }

    // Replace winnerTacticsArray with player1TacticsArray
    const setPlayer1TacticsAlliance = async () => {
        // Tactics
        for(const turn of player1TacticsArray) {
            // turn[1][1] ? increment(1) : increment(0) allows us to increment or not increment based on if a tactic has been achieved or not. 
            const player1TacticRef = doc(db, statsRef, gameType, "faction", gameData.player1.alliance, "Battle Tactics", turn[1][0]);
            // If tactic is achieved
            await setDoc(player1TacticRef, {
                taken: increment(1),
                achieved: turn[1][1] ? increment(1) : increment(0),
                [turn[0]]: increment(1),
                date: {
                    [gameData.date]: {
                        taken: increment(1),
                        achieved: turn[1][1] ? increment(1) : increment(0),
                        [turn[0]]: increment(1),
                    }
                },
                region: {
                    [gameData.region.label]: {
                        taken: increment(1),
                        achieved: turn[1][1] ? increment(1) : increment(0),
                        [turn[0]]: increment(1),
                    }
                },
                bySubFaction: {
                    [gameData.player1.subfaction]: {
                        taken: increment(1),
                        achieved: turn[1][1] ? increment(1) : increment(0),
                        turnTaken: {
                            [turn[0]]: increment(1),
                        },
                        date: {
                            [gameData.date]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        }
                    }
                },
                mission: {
                    [gameData.mission]: {
                        taken: increment(1),
                        achieved: turn[1][1] ? increment(1) : increment(0),
                        turnTaken: {
                            [turn[0]]: increment(1),
                        },
                        date: {
                            [gameData.date]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        }
                    }
                },
                points: {
                    [gameData.points]: {
                        taken: increment(1),
                        achieved: turn[1][1] ? increment(1) : increment(0),
                        turnTaken: {
                            [turn[0]]: increment(1),
                        },
                        date: {
                            [gameData.date]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        }
                    }
                },
                opponent: {
                    [gameData.player2.alliance]: {
                        taken: increment(1),
                        achieved: turn[1][1] ? increment(1) : increment(0),
                        turnTaken: {
                            [turn[0]]: increment(1),
                        },
                        date: {
                            [gameData.date]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        },
                        [gameData.player2.faction]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            turnTaken: {
                                [turn[0]]: increment(1),
                            },
                            date: {
                                [gameData.date]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                            [gameData.player2.subfaction]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                turnTaken: {
                                    [turn[0]]: increment(1),
                                },
                                date: {
                                    [gameData.date]: {
                                        taken: increment(1),
                                        achieved: turn[1][1] ? increment(1) : increment(0),
                                        [turn[0]]: increment(1),
                                    }
                                },
                                region: {
                                    [gameData.region.label]: {
                                        taken: increment(1),
                                        achieved: turn[1][1] ? increment(1) : increment(0),
                                        [turn[0]]: increment(1),
                                    }
                                },
                            }
                        }
                    }
                }
            }, { merge: true });
        }
    }

    // Replace winnerTacticsArray with player1TacticsArray
    const setPlayer1TacticsFaction = async () => {
        // Tactics
        for(const turn of player1TacticsArray) {
            // turn[1][1] ? increment(1) : increment(0) allows us to increment or not increment based on if a tactic has been achieved or not. 
            const player1TacticRef = doc(db, statsRef, gameType, "faction", gameData.player1.alliance, gameData.player1.faction, "Battle Tactics");
            // If tactic is achieved
            await setDoc(player1TacticRef, {
                [turn[1][0]]: {
                    taken: increment(1),
                    achieved: turn[1][1] ? increment(1) : increment(0),
                    [turn[0]]: increment(1),
                    date: {
                        [gameData.date]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            [turn[0]]: increment(1),
                        }
                    },
                    region: {
                        [gameData.region.label]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            [turn[0]]: increment(1),
                        }
                    },
                    bySubFaction: {
                        [gameData.player1.subfaction]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            turnTaken: {
                                [turn[0]]: increment(1),
                            },
                            date: {
                                [gameData.date]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                        }
                    },
                    mission: {
                        [gameData.mission]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            turnTaken: {
                                [turn[0]]: increment(1),
                            },
                            date: {
                                [gameData.date]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                        }
                    },
                    points: {
                        [gameData.points]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            turnTaken: {
                                [turn[0]]: increment(1),
                            },
                            date: {
                                [gameData.date]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                        }
                    },
                    opponent: {
                        [gameData.player2.alliance]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            turnTaken: {
                                [turn[0]]: increment(1),
                            },
                            date: {
                                [gameData.date]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                            [gameData.player2.faction]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                turnTaken: {
                                    [turn[0]]: increment(1),
                                },
                                date: {
                                    [gameData.date]: {
                                        taken: increment(1),
                                        achieved: turn[1][1] ? increment(1) : increment(0),
                                        [turn[0]]: increment(1),
                                    }
                                },
                                region: {
                                    [gameData.region.label]: {
                                        taken: increment(1),
                                        achieved: turn[1][1] ? increment(1) : increment(0),
                                        [turn[0]]: increment(1),
                                    }
                                },
                                [gameData.player2.subfaction]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    turnTaken: {
                                        [turn[0]]: increment(1),
                                    },
                                    date: {
                                        [gameData.date]: {
                                            taken: increment(1),
                                            achieved: turn[1][1] ? increment(1) : increment(0),
                                            [turn[0]]: increment(1),
                                        }
                                    },
                                    region: {
                                        [gameData.region.label]: {
                                            taken: increment(1),
                                            achieved: turn[1][1] ? increment(1) : increment(0),
                                            [turn[0]]: increment(1),
                                        }
                                    },
                                }
                            }
                        }
                    }
                }
            }, { merge: true });
        }
    }

    const setPlayer2Faction = async () => {

        const player2FactionRef = doc(db, statsRef, gameType, "faction", gameData.player2.alliance, gameData.player2.faction, "Wins");
        
        await setDoc(doc(db, statsRef, gameType, "faction", gameData.player2.alliance), {
            gamesPlayed: increment(1),
            turnsCompleted: increment(gameData.turnEndedOn),
            wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
            losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
            draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
        }, { merge: true });

        await setDoc(doc(db, statsRef, gameType, "faction", gameData.player2.alliance, gameData.player2.faction, "Overall"), {
            gamesPlayed: increment(1),
            turnsCompleted: increment(gameData.turnEndedOn),
            wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
            losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
            draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
        }, { merge: true });

        await setDoc(player2FactionRef, {
            wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
            gamesPlayed: increment(1),
            turnsCompleted: increment(gameData.turnEndedOn),
            date: {
                [gameData.date]: {
                    wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                    gamesPlayed: increment(1),
                    turnsCompleted: increment(gameData.turnEndedOn),
                }
            },
            region: {
                [gameData.region.label]: {
                    wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                    gamesPlayed: increment(1),
                    turnsCompleted: increment(gameData.turnEndedOn),
                }
            },
            mission: {
                [gameData.mission]: {
                    wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                    turnsCompleted: increment(gameData.turnEndedOn),
                    gamesPlayed: increment(1),
                    date: {
                        [gameData.date]: {
                            wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                    region: {
                        [gameData.region.label]: {
                            wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                }
            },
            points: {
                [gameData.points]: {
                    wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                    turnsCompleted: increment(gameData.turnEndedOn),
                    gamesPlayed: increment(1),
                    date: {
                        [gameData.date]: {
                            wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                    region: {
                        [gameData.region.label]: {
                            wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                    mission: {
                        [gameData.mission]: {
                            wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                            turnsCompleted: increment(gameData.turnEndedOn),
                            gamesPlayed: increment(1),
                            date: {
                                [gameData.date]: {
                                    wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                                    gamesPlayed: increment(1),
                                    turnsCompleted: increment(gameData.turnEndedOn),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                                    gamesPlayed: increment(1),
                                    turnsCompleted: increment(gameData.turnEndedOn),
                                }
                            },
                        },
                    }
                }
            },
            opponent: { 
                [gameData.player1.alliance]: {
                    wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                    turnsCompleted: increment(gameData.turnEndedOn),
                    gamesPlayed: increment(1),
                    date: {
                        [gameData.date]: {
                            wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                    region: {
                        [gameData.region.label]: {
                            wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                            gamesPlayed: increment(1),
                            turnsCompleted: increment(gameData.turnEndedOn),
                        }
                    },
                    [gameData.player1.faction]: {
                        wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                        turnsCompleted: increment(gameData.turnEndedOn),
                        gamesPlayed: increment(1),
                        date: {
                            [gameData.date]: {
                                wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                                gamesPlayed: increment(1),
                                turnsCompleted: increment(gameData.turnEndedOn),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                                gamesPlayed: increment(1),
                                turnsCompleted: increment(gameData.turnEndedOn),
                            }
                        },
                        [gameData.player1.subfaction]: {
                            wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                            turnsCompleted: increment(gameData.turnEndedOn),
                            gamesPlayed: increment(1),
                            date: {
                                [gameData.date]: {
                                    wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                                    gamesPlayed: increment(1),
                                    turnsCompleted: increment(gameData.turnEndedOn),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                                    gamesPlayed: increment(1),
                                    turnsCompleted: increment(gameData.turnEndedOn),
                                }
                            },
                        }
                    }
                }
            }
        }, { merge: true });
    }

    const setPlayer2SubFaction = async () => {
        const player2SubFactionRef = doc(db, statsRef, gameType, "faction", gameData.player2.alliance, gameData.player2.faction, gameData.player2.subfaction);
        await setDoc(player2SubFactionRef, {
            wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
            losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
            draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
            gamesPlayed: increment(1),
            turnsCompleted: increment(gameData.turnEndedOn),
            date: {
                [gameData.date]: {
                    wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                    losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                    draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                    gamesPlayed: increment(1),
                    turnsCompleted: increment(gameData.turnEndedOn),
                }
            },
            region: {
                [gameData.region.label]: {
                    wins: gameData.player1.score.total < gameData.player2.score.total ? increment(1) : increment(0),
                    losses: gameData.player1.score.total > gameData.player2.score.total ? increment(1) : increment(0),
                    draws: gameData.player1.score.total === gameData.player2.score.total ? increment(1) : increment(0),
                    gamesPlayed: increment(1),
                    turnsCompleted: increment(gameData.turnEndedOn),
                }
            },
        }, { merge: true });
    }

    const setPlayer2StratAlliance = async () => {
        // Grand Strategies
        const player2StratRef = doc(db, statsRef, gameType, "faction", gameData.player2.alliance, "Grand Strategies", gameData.player2.grandstrategy.chosen);

        // Increment achieved only if true
        await setDoc(player2StratRef, {
            achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
            takenCount: increment(1),
            date: {
                [gameData.date]: {
                    achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                    takenCount: increment(1),
                }
            },
            region: {
                [gameData.region.label]: {
                    achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                    takenCount: increment(1),
                }
            },
            mission: {
                [gameData.mission]: {
                    [gameData.player2.grandstrategy.chosen]: {
                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                        date: {
                            [gameData.date]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                    },
                }
            },
            points: {
                [gameData.points]: {
                    [gameData.player2.grandstrategy.chosen]: {
                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                        date: {
                            [gameData.date]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                    },
                    mission: {
                        [gameData.mission]: {
                            [gameData.player2.grandstrategy.chosen]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                                date: {
                                    [gameData.date]: {
                                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                                region: {
                                    [gameData.region.label]: {
                                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                            }
                        }
                    }
                }
            },
            opponent: {
                [gameData.player1.alliance]: {
                    [gameData.player2.grandstrategy.chosen]: {
                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                        date: {
                            [gameData.date]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                    },
                    [gameData.player1.faction]: {
                        [gameData.player2.grandstrategy.chosen]: {
                            achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                            takenCount: increment(1),
                            date: {
                                [gameData.date]: {
                                    achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                    takenCount: increment(1),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                    takenCount: increment(1),
                                }
                            },
                        },
                        [gameData.player1.subfaction]: {
                            [gameData.player2.grandstrategy.chosen]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                                date: {
                                    [gameData.date]: {
                                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                                region: {
                                    [gameData.region.label]: {
                                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                            },
                        }
                    }
                }
            }
        }, { merge: true });
    }

    const setPlayer2StratFaction = async () => {
        // Grand Strategies
        const player2StratRef = doc(db, statsRef, gameType, "faction", gameData.player2.alliance, gameData.player2.faction, "Grand Strategy");
        await setDoc(player2StratRef, {
            [gameData.player2.grandstrategy.chosen]: {
                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                takenCount: increment(1),
                date: {
                    [gameData.date]: {
                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                    }
                },
                region: {
                    [gameData.region.label]: {
                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                    }
                },
            },
            mission: {
                [gameData.mission]: {
                    [gameData.player2.grandstrategy.chosen]: {
                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                        date: {
                            [gameData.date]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                    },
                }
            },
            points: {
                [gameData.points]: {
                    [gameData.player2.grandstrategy.chosen]: {
                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                        date: {
                            [gameData.date]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                    },
                    mission: {
                        [gameData.mission]: {
                            [gameData.player2.grandstrategy.chosen]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                                date: {
                                    [gameData.date]: {
                                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                                region: {
                                    [gameData.region.label]: {
                                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                            },
                        }
                    }
                }
            },
            opponent: {
                [gameData.player1.alliance]: {
                    [gameData.player2.grandstrategy.chosen]: {
                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                        takenCount: increment(1),
                        date: {
                            [gameData.date]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                            }
                        },
                    },
                    [gameData.player1.faction]: {
                        [gameData.player2.grandstrategy.chosen]: {
                            achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                            takenCount: increment(1),
                            date: {
                                [gameData.date]: {
                                    achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                    takenCount: increment(1),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                    takenCount: increment(1),
                                }
                            },
                        },
                        [gameData.player1.subfaction]: {
                            [gameData.player2.grandstrategy.chosen]: {
                                achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                takenCount: increment(1),
                                date: {
                                    [gameData.date]: {
                                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                                region: {
                                    [gameData.region.label]: {
                                        achieved: gameData.player2.grandstrategy.achieved ? increment(1) : increment(0),
                                        takenCount: increment(1),
                                    }
                                },
                            },
                        }
                    }
                }
            }
        }, { merge: true });
    }

    // Replace winnerTacticsArray with player1TacticsArray
    const setPlayer2TacticsAlliance = async () => {
        for(const turn of player2TacticsArray) {
            // turn[1][1] ? increment(1) : increment(0) allows us to increment or not increment based on if a tactic has been achieved or not. 
            const player2TacticRef = doc(db, statsRef, gameType, "faction", gameData.player2.alliance, "Battle Tactics", turn[1][0]);
            // If tactic is achieved
            await setDoc(player2TacticRef, {
                taken: increment(1),
                achieved: turn[1][1] ? increment(1) : increment(0),
                [turn[0]]: increment(1),
                date: {
                    [gameData.date]: {
                        taken: increment(1),
                        achieved: turn[1][1] ? increment(1) : increment(0),
                        [turn[0]]: increment(1),
                    }
                },
                region: {
                    [gameData.region.label]: {
                        taken: increment(1),
                        achieved: turn[1][1] ? increment(1) : increment(0),
                        [turn[0]]: increment(1),
                    }
                },
                bySubFaction: {
                    [gameData.player2.subfaction]: {
                        taken: increment(1),
                        achieved: turn[1][1] ? increment(1) : increment(0),
                        turnTaken: {
                            [turn[0]]: increment(1),
                        },
                        date: {
                            [gameData.date]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        }
                    }
                },
                mission: {
                    [gameData.mission]: {
                        taken: increment(1),
                        achieved: turn[1][1] ? increment(1) : increment(0),
                        turnTaken: {
                            [turn[0]]: increment(1),
                        },
                        date: {
                            [gameData.date]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        }
                    }
                },
                points: {
                    [gameData.points]: {
                        taken: increment(1),
                        achieved: turn[1][1] ? increment(1) : increment(0),
                        turnTaken: {
                            [turn[0]]: increment(1),
                        },
                        date: {
                            [gameData.date]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        }
                    }
                },
                opponent: {
                    [gameData.player1.alliance]: {
                        taken: increment(1),
                        achieved: turn[1][1] ? increment(1) : increment(0),
                        turnTaken: {
                            [turn[0]]: increment(1),
                        },
                        date: {
                            [gameData.date]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        },
                        region: {
                            [gameData.region.label]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                [turn[0]]: increment(1),
                            }
                        },
                        [gameData.player1.faction]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            turnTaken: {
                                [turn[0]]: increment(1),
                            },
                            date: {
                                [gameData.date]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                            [gameData.player1.subfaction]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                turnTaken: {
                                    [turn[0]]: increment(1),
                                },
                                date: {
                                    [gameData.date]: {
                                        taken: increment(1),
                                        achieved: turn[1][1] ? increment(1) : increment(0),
                                        [turn[0]]: increment(1),
                                    }
                                },
                                region: {
                                    [gameData.region.label]: {
                                        taken: increment(1),
                                        achieved: turn[1][1] ? increment(1) : increment(0),
                                        [turn[0]]: increment(1),
                                    }
                                },
                            }
                        }
                    }
                }
            }, { merge: true });
        }
    }

    // Replace winnerTacticsArray with player1TacticsArray
    const setPlayer2TacticsFaction = async () => {
        // Tactics
        for(const turn of player2TacticsArray) {
            // turn[1][1] ? increment(1) : increment(0) allows us to increment or not increment based on if a tactic has been achieved or not. 
            const player2TacticRef = doc(db, statsRef, gameType, "faction", gameData.player2.alliance, gameData.player2.faction, "Battle Tactics");
            // If tactic is achieved
            await setDoc(player2TacticRef, {
                [turn[1][0]]: {
                    taken: increment(1),
                    achieved: turn[1][1] ? increment(1) : increment(0),
                    [turn[0]]: increment(1),
                    date: {
                        [gameData.date]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            [turn[0]]: increment(1),
                        }
                    },
                    region: {
                        [gameData.region.label]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            [turn[0]]: increment(1),
                        }
                    },
                    bySubFaction: {
                        [gameData.player2.subfaction]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            turnTaken: {
                                [turn[0]]: increment(1),
                            },
                            date: {
                                [gameData.date]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                        }
                    },
                    mission: {
                        [gameData.mission]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            turnTaken: {
                                [turn[0]]: increment(1),
                            },
                            date: {
                                [gameData.date]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                        }
                    },
                    points: {
                        [gameData.points]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            turnTaken: {
                                [turn[0]]: increment(1),
                            },
                            date: {
                                [gameData.date]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                        }
                    },
                    opponent: {
                        [gameData.player1.alliance]: {
                            taken: increment(1),
                            achieved: turn[1][1] ? increment(1) : increment(0),
                            turnTaken: {
                                [turn[0]]: increment(1),
                            },
                            date: {
                                [gameData.date]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                            region: {
                                [gameData.region.label]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    [turn[0]]: increment(1),
                                }
                            },
                            [gameData.player1.faction]: {
                                taken: increment(1),
                                achieved: turn[1][1] ? increment(1) : increment(0),
                                turnTaken: {
                                    [turn[0]]: increment(1),
                                },
                                date: {
                                    [gameData.date]: {
                                        taken: increment(1),
                                        achieved: turn[1][1] ? increment(1) : increment(0),
                                        [turn[0]]: increment(1),
                                    }
                                },
                                region: {
                                    [gameData.region.label]: {
                                        taken: increment(1),
                                        achieved: turn[1][1] ? increment(1) : increment(0),
                                        [turn[0]]: increment(1),
                                    }
                                },
                                [gameData.player1.subfaction]: {
                                    taken: increment(1),
                                    achieved: turn[1][1] ? increment(1) : increment(0),
                                    turnTaken: {
                                        [turn[0]]: increment(1),
                                    },
                                    date: {
                                        [gameData.date]: {
                                            taken: increment(1),
                                            achieved: turn[1][1] ? increment(1) : increment(0),
                                            [turn[0]]: increment(1),
                                        }
                                    },
                                    region: {
                                        [gameData.region.label]: {
                                            taken: increment(1),
                                            achieved: turn[1][1] ? increment(1) : increment(0),
                                            [turn[0]]: increment(1),
                                        }
                                    },
                                }
                            }
                        }
                    }
                }
            }, { merge: true });
        }
    }

    setGameType();
    setPoints();
    setPlayer1Faction();
    setPlayer1SubFaction();
    setPlayer2Faction();
    setPlayer2SubFaction();
    if (gameData.gameType === "Matched Play") {
        setPlayer1StratAlliance();
        setPlayer1StratFaction();
        setPlayer1TacticsAlliance();
        setPlayer1TacticsFaction();
        setPlayer2StratAlliance();
        setPlayer2StratFaction();
        setPlayer2TacticsAlliance();
        setPlayer2TacticsFaction();
    }

    playerData();

    console.log('Send Data Func: ', gameData);
} 