import React, {useState} from 'react';
import { 
    signInWithEmailAndPassword, 
    GoogleAuthProvider,
    signInWithPopup,
    getRedirectResult, 
} from 'firebase/auth';
import { auth } from '../firebase';
import { getAuth } from 'firebase/auth'; 
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { ResetPassword } from './ResetPassword';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import GoogleIcon from '@mui/icons-material/Google';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'; 
import LoginIcon from '@mui/icons-material/Login';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
 
export function Login({referrer}) {
    let location = useLocation();
    let pathBack = "";
    if (location.state) {
        pathBack = location.state.path;
    } else {
        pathBack = referrer;
    }

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordIncorrect, setPasswordIncorrect] = useState(false);
       
    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            navigate(pathBack)
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            
            setPasswordIncorrect(true);
            console.log(errorCode, errorMessage, 'Incorrect Password: ', passwordIncorrect);
        });
       
    }

    const auth = getAuth();

    const provider = new GoogleAuthProvider();
    const signInWithGoogle = async () => {
        signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          // IdP data available using getAdditionalUserInfo(result)
          // ...
          navigate('/my-account')
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    };

    getRedirectResult(auth)
    .then((result) => {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;

        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        navigate(pathBack)
    }).catch((error) => {
        // Handle Errors here.
    });
 
    return (
        <div>                                            
            <h1>Sign in to Scorehammer</h1>
            <p>Your account will enable you to track past and future games.</p>                                                                        
            <Box
                component="div"
                sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >                                                                                            
                <div>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="outlined-email">Email Address</InputLabel>
                        <OutlinedInput
                            id="outlined-email"
                            type="email"
                            label="Password"
                            value={email}
                            required={true}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            value={password}
                            required={true}
                            onChange={(e) => setPassword(e.target.value)}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>                                             
                <Box>
                    <Button onClick={onLogin} value="Login" variant="contained" size="large" aria-label="Login" color="primary" endIcon={<LoginIcon />}>Login</Button>
                    <p>{passwordIncorrect ? "Password is incorrect" : ""}</p>
                </Box>
                <Box>
                    <Button onClick={signInWithGoogle} value="login with Google" variant="contained" size="large" aria-label="Sign Up with Google" color="primary" endIcon={<GoogleIcon />}>Login with Google</Button>
                </Box>
                <Box>   
                    <ResetPassword val={"Forgot Password"} email={email}  />
                </Box>  
            </Box>                   
            <p className="text-sm text-white text-center">
                No account yet? {' '}
                <NavLink to="/signup" state={{ path: pathBack }}>
                    <Button value="Sign up" variant="outlined" size="small" aria-label="Sign up" color="primary" endIcon={<PersonAddIcon />}>Sign up</Button>
                </NavLink>
            </p>

                                                   
        </div>
    )
}