import * as React from 'react';
import Points from './Points';
import Season from './Season';
import { useStickyState } from '../../utils/useStickyState';
import { useFormContext } from "react-hook-form";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

export default function GameType() {
  const [gameType, setGameType] = useStickyState('', 'Game Type');
  const { register } = useFormContext();
  const gameTypes = ['Narrative', 'Open Play', 'Matched Play']
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGameType(event.target.value);
  };

  return (
    <div>
        <h2>Game Type</h2>
        <Box
            component="div"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
          <TextField
              id="standard-select-gametype"
              select
              variant="standard"
              name="Choose game type"
              label="Game Type"
              value={gameType}
              required={true}
              helperText={`Select Game Type`}
              {...register('Game Type', {
                shouldUnregister: true,
                onChange: handleChange
              })}
            >
              {Object.keys(gameTypes).map((option, i) => (
                  <MenuItem key={i} value={gameTypes[option]}>
                      {gameTypes[option]}
                  </MenuItem>
              ))}
          </TextField>
        </Box>
        <div className="selectors">
            <Points gametype={gameType} />
        </div>
        <div className="selectors">
            <Season gametype={gameType} />
        </div>
    </div>
  )

}