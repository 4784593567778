import * as React from 'react';
import Belligerant from './Belligerant';

export default function Players({player_1_name, on_change_player_1_name, player_2_name, on_change_player_2_name, uniqueUrl}) {
  return (
    <div>
        <h2>Belligerants</h2>
        <div className="selectors">
        <Belligerant player="Player 1" name={"Player 1 " + player_1_name} nameChange={on_change_player_1_name} uniqueUrl={uniqueUrl}/>
        <Belligerant player="Player 2" name={"Player 1 " + player_2_name} nameChange={on_change_player_2_name} uniqueUrl={uniqueUrl}/>
        </div>
    </div>
  )

}