// Delete me when you delete data console log. 
import React from "react";
import { useStickyState } from "../utils/useStickyState";
import { useFormContext } from "react-hook-form";
import { db } from "../../firebase";
import { setDoc, doc } from "firebase/firestore"; 
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

const BattleTactic = ({ player, turn, uurl, defaultData }) => {
    // Todos: Update Turn and Player to not require this change in every consuming component. 
    let playerName = player.replace(/\s+/g,'').toLowerCase();
    let turnEdit = "t" + turn;
    // Define initial variables, with default option for form
    const [btAchieved, setBt] = useStickyState(false, player + ' Battle Tactic ' + turn + ' Achieved');
    // Function required to bubble values from this form input up to the parent form in Register.js
    const { register } = useFormContext();

    // Update the local storage if battle tactic is achieved or not. 
    const handleTChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBt(event.target.checked);

    updateTactic(event.target.checked);
    };

    const updateTactic = async (e) => {
        await setDoc(doc(db, `games/${uurl}`), {
            [playerName]: {
            battletactics: {
                [turnEdit]: {
                    achieved: e,
                }
            },
            },
        }, { merge: true });

        // Set achieved in defaultData to manage state in this component. 
        defaultData[playerName].battletactics[turnEdit].achieved = e;
    }

    return (
        <>
        <Box
            component="div"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            size="small"
            noValidate
            autoComplete="off"
            >
            <div className="achieved">
                <FormControlLabel control={
                    <Checkbox 
                        checked={btAchieved} 
                        onChange={handleTChange}
                        {...register(player + ' Battle Tactic turn ' + turn + ' achieved', {
                            onChange: handleTChange
                        })}
                    />
                } />
            </div>
        </Box>
        </>
    )

}

export default BattleTactic;

