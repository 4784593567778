import * as React from "react";
import Army from "./Army";

export default function Armies({player_1_name, player_2_name}) {
  return (
    <div>
        <h2>Armies</h2>
        <div className="selectors">
            <p>Player 1: { player_1_name }</p>
            <Army player="Player 1" />
            <p>Player 2: { player_2_name }</p>
            <Army player="Player 2" />
        </div>
    </div>

  )
}
