import React from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { AlliancePopularity, AllianceGameLength, AllianceLeaderboard } from './matched/alliance/Popularity';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}
  


export function StatsCenter() {

    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }} className="stats-center">  
            
            <Box sx={{ width: '100%' }} component={Paper} elevation={3}  className='stats-wrapper'>
                <h1>Welcome to the Stats Center</h1>
                <p>Here we show the data gathered from all <i>ended</i> games players have submitted.
                The aim is to display interesting data from all modes of play.
                If you want to see what Battle Tactics are most succesful in which turns, you're in the right place.
                If you'd like to see which matched play missions are most popular, and which armies struggle to make it past turn three, we've got you covered.
                    </p>
                <span className="info"><small>{<InfoIcon />}This data is a sample of all games played. It can be filtered down to Matched Play, but this should not be taken as being a sample of <i>competitive play</i>. Plenty of non-competitive people use the Matched Play system to conduct their games and this data represents that. If you'd like to see the competitive meta, please check out the lovingly and comprehensively built Stats Center over at the <a href="www.thehonestwargamer.com" target="_blank" rel="nofollow">thehonestwargamer.com</a></small></span>
            </Box>
            {/* Tabs */}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered className='stats-tabs'>
                    <Tab label="Matched Play" {...a11yProps(0)} />
                    <Tab label="Narrative" {...a11yProps(1)} disabled={true} />
                    <Tab label="Open" {...a11yProps(2)} disabled={true} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} className="stats overall">
                <h2>Overall</h2>
                <div className="stat-wrapper">
                    <AllianceLeaderboard />
                    <AlliancePopularity />
                    <AllianceGameLength />
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                Narrative
            </TabPanel>
            <TabPanel value={value} index={2}>
                Open
            </TabPanel>

        </Box>
    )
}