import React from 'react';

export function Legend() {

    return (
        <>
            <div className='legend'>
                <ul>
                    <li><small><strong>*:</strong> TC or Turns Completed is the average amount of turns you complete in one game.</small></li>
                    <li><small><strong>±:</strong> P is the total number of Games Played</small></li>
                </ul>
            </div>
        </>
    )
}
