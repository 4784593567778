// Delete me when you delete data console log. 
import React, { useState } from "react";
import { useStickyState } from "../utils/useStickyState";
import { useFormContext } from "react-hook-form";
import { db } from "../../firebase";
import { setDoc, doc } from "firebase/firestore"; 
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import GrandStrategy from "./GrandStrategy";

const Total = ({ player, faction, alliance, season, uurl, defaultData }) => {
    // Todos: Update Turn and Player to not require this change in every consuming component. 
    let playerName = player.replace(/\s+/g,'').toLowerCase();
    // Define initial variables, with default option for form
    const [gaAchieved, setGa] = useStickyState(false, player + ' Grand Strategy Achieved');
    const [checked, setChecked] = useState(false);

    // Function required to bubble values from this form input up to the parent form in Register.js
    const { register } = useFormContext();

    let totalVps = defaultData[playerName].score.total;

    // Handle the toggle of the checkbox
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGa(event.target.checked);
        setChecked(event.target.checked);

        updateGrandStrat(event.target.checked);
    };

    const updateGrandStrat = async (e) => {
        await setDoc(doc(db, `games/${uurl}`), {
            [playerName]: {
                grandstrategy: {
                    achieved: e,
                }
            },
        }, { merge: true });

        // Set achieved in defaultData to manage state in this component. 
        defaultData[playerName].grandstrategy.achieved = e;
    }
    
    return (
        <>  
            <GrandStrategy player={player} faction={faction} alliance={alliance} season={season} uurl={uurl} defaultData={defaultData} />
            <div className="totals">
                <span>
                    <p><strong>Grand Strategy Achieved:</strong></p>
                    <FormGroup className='check'>
                        <FormControlLabel control={
                            <Checkbox
                                onChange={handleChange}
                                checked={gaAchieved}
                                {...register(player + ' Grand Strategy Achieved', {
                                    shouldUnregister: true,
                                    onChange: handleChange
                                })}
                            />
                        } />
                    </FormGroup>
                </span>
                <p><strong>Total: &nbsp;</strong> {totalVps}</p>
            </div>
        </>
    )

}
export default Total;

