// Delete me when you delete data console log. 
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useStickyState } from "../utils/useStickyState";
import { db } from "../../firebase";
import { setDoc, doc } from "firebase/firestore"; 
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const Input = ({ player, turn, value, uurl, defaultData }) => {
    // Todos: Update Turn and Player to not require this change in every consuming component. 
    let playerName = player.replace(/\s+/g,'').toLowerCase();
    let turnEdit = "t" + turn;
    const [localScore, setLocalScore] = useStickyState(0, player + " Score t" + turn);
    // Define initial variables, with default option for form
    const [score, setScore] = useState( defaultData[playerName].score[turnEdit] ? defaultData[playerName].score[turnEdit] : 0 );
    
    // Function required to bubble values from this form input up to the parent form in Register.js
    const { register } = useFormContext();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setScore(event.target.value);
        setLocalScore(Number(event.target.value));
        updateScore(Number(event.target.value));
    };

    // Set the score for this turn in the database to preserve it on page refresh.
    const updateScore = async (e) => {
        await setDoc(doc(db, `games/${uurl}`), {
            [playerName]: {
                score: {
                    [turnEdit]: e
                }
            },
        }, { merge: true });
    
        // Set the score for this turn in defaultData to manage state in this component. 
        defaultData[playerName].score[turnEdit] = e;
    }

    return (
        <>
            <Box
                component="div"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '5ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <TextField
                    id="standard-number"
                    type="number"
                    variant="standard"
                    value={score}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...register(player + ' Turn ' + turn + ' ' + value, {
                        onChange: handleChange
                    })}
                />
            </Box>
        </>
    )

}
export default Input;

