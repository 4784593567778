import React, { useState, useEffect } from 'react';
import { getDocs, collection } from "firebase/firestore"; 
import { db } from '../firebase';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export function AllianceLeaderboard() {
    // Game Data
    // Using a state allows us to fetch this on mount and show a loaded beforehand
    const [gameData, setGameData] = useState([]);
    const [dataReady, setDataReady] = useState(false);

    useEffect(() => {
        let timer1 = setTimeout(() => {
            fetchGameData();
        }, 500);

        return () => {
            clearTimeout(timer1);
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    let buildList = [];
    let content;

    const fetchGameData = async () => {

        const querySnapshot = await getDocs(collection(db, "/stats/matched/faction/"));
        querySnapshot.forEach((doc) => {
            // Use a standard array to organise the data
            buildList.push([doc.id, [doc.data()]]);
        });

        if (buildList.length === querySnapshot.docs.length) {
            // When the list is complete, render the table
            setGameData(buildList);
            setDataReady(true);
        }

    }

    if (dataReady) {
        content = (
            gameData.map((data) => { 
                return (
                    <React.Fragment key={data[0]}>
                        <TableRow>
                            <TableCell>
                                { data[0] }
                            </TableCell>
                            <TableCell>
                                { data[1][0].wins ? data[1][0].wins : 0 }
                            </TableCell>
                            <TableCell>
                                { data[1][0].losses ? data[1][0].losses : 0 }
                            </TableCell>
                            <TableCell>
                                { data[1][0].draws ? data[1][0].draws : 0 }
                            </TableCell>
                            <TableCell>
                                { data[1][0].gamesPlayed ? ((data[1][0].wins / data[1][0].gamesPlayed) * 100).toFixed(1) + '%' : 0 }
                            </TableCell>
                        </TableRow>
                    </React.Fragment>
                );   
            })
        );

    } else {
        content = (
            <>
                <TableRow>
                    <TableCell className='fetching-data'>
                        Fetching Data <CircularProgress />
                    </TableCell>
                </TableRow>
            </>
        );
    }

    return (
        <Box className="stats-form alliance-stats" component={Paper} elevation={3}>                                   
            <TableContainer sx={{ minHeight: 320 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={5}>
                                Alliance Standings
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Alliance
                            </TableCell>
                            <TableCell>
                                W
                            </TableCell>
                            <TableCell>
                                L
                            </TableCell>
                            <TableCell>
                                D
                            </TableCell>
                            <TableCell>
                                W/L
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className='stats-body'>
                        {content}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export function AllianceGameStats() {
    // Game Data
    // Using a state allows us to fetch this on mount and show a loaded beforehand
    const [gameData, setGameData] = useState([]);
    const [dataReady, setDataReady] = useState(false);

    useEffect(() => {
        let timer1 = setTimeout(() => {
            fetchGameData();
        }, 500);

        return () => {
            clearTimeout(timer1);
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    let buildList = [];
    let content;

    const fetchGameData = async () => {

        const querySnapshot = await getDocs(collection(db, "/stats/matched/faction/"));
        querySnapshot.forEach((doc) => {
            // Use a standard array to organise the data
            buildList.push([doc.id, [doc.data()]]);
        });

        if (buildList.length === querySnapshot.docs.length) {
            // When the list is complete, render the table
            setGameData(buildList);
            setDataReady(true);
        }

    }

    if (dataReady) {
        content = (
            gameData.map((data) => { 
                return (
                    <React.Fragment key={data[0]}>  
                        <TableRow>
                            <TableCell>
                                { data[0] }
                            </TableCell>
                            <TableCell>
                                { data[1][0].gamesPlayed ? data[1][0].gamesPlayed : 0 }
                            </TableCell>
                            <TableCell>
                                { data[1][0].gamesPlayed ? (data[1][0].turnsCompleted / data[1][0].gamesPlayed).toFixed(1) + " Turns" : '0' }
                            </TableCell>
                        </TableRow>
                    </React.Fragment>  
                );
            })
        );

    } else {
        content = (
            <>
                <TableRow>
                    <TableCell className='fetching-data'>
                        Fetching Data <CircularProgress />
                    </TableCell>
                </TableRow>
            </>
        );
    }

    return (
        <Box className="stats-form alliance-stats" component={Paper} elevation={3}>                                  
            <TableContainer sx={{ minHeight: 320 }}>
                <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={3}>
                            Alliance Game Length
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Alliance
                        </TableCell>
                        <TableCell>
                            Games Played
                        </TableCell>
                        <TableCell>
                            Avg Game Length
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className='stats-body'>
                    {content}
                </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}