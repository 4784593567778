import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom'
import { onAuthStateChanged, signOut } from "firebase/auth";
import LogoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import LogRocket from 'logrocket';
 
export default function LogoutButton() {
    const navigate = useNavigate();

    const [loggedIn, setLoggedIn] = useState(false);

    const handleLogout = () => {               
        signOut(auth).then(() => {
        // Sign-out successful.
            navigate("/");
            console.log("Signed out successfully");
        }).catch((error) => {
        // An error happened.
        });
    }

    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                // ...
                // console.log('user is logged in', 'uid', uid, user);
                setLoggedIn(true);

                // This is an example script - don't forget to change it!
                LogRocket.identify(uid, {
                    name: user.displayName ? user.displayName : 'Anonymous',
                    email: user.email,
                });
            } else {
                // User is signed out
                // ...
                console.log("user is logged out");
                setLoggedIn(false);
            }
        });
    }, [])
 
    return <div>{loggedIn ? <Button onClick={handleLogout} value="Logout" variant="outlined" size="small" aria-label="Logout" color="primary" endIcon={<LogoutIcon />}>Logout</Button> : ''}</div>;
}