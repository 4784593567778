import * as React from 'react';
import { useStickyState } from "../../utils/useStickyState";
import { useFormContext } from "react-hook-form";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export default function Points({gametype}) { 
    // Define initial variables, with default option for form
    const [points, setPoints] = useStickyState('2000', 'Points');

    const matchedPoints = ['1000', '1500', '2000'];
    const openPoints = ['750', '2000', '2000+'];

    let pointsValues = '';
    if (gametype === "Matched Play") {
        pointsValues = matchedPoints;
    } else {
        pointsValues = openPoints;
    }

    // Function required to bubble values from this form input up to the parent form in Register.js
    const { register } = useFormContext();
    // When the form element changes, we update the value in stickyState
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPoints(event.target.value);
    };

    let content; 
    if ((gametype === "Matched Play") || (gametype === "Open Play")) {
        content = (
            <Box
                component="div"
                sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        id="standard-select-points"
                        select
                        variant="standard"
                        name="Choose points limit"
                        label="Points Limit"
                        value={points}
                        required={true}
                        helperText={`Select Points Limit`}
                        {...register('Points', {
                        shouldUnregister: true,
                        onChange: handleChange
                        })}
                    >
                        {Object.keys(pointsValues).map((option, i) => (
                            <MenuItem key={i} value={pointsValues[option]}>
                                {pointsValues[option]}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </Box>
        );
    }

    return <div>{content}</div>

}