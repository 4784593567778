import React, { useState, useEffect } from 'react';
import { getDocs, collection } from "firebase/firestore"; 
import { db } from '../../../firebase';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

Chart.register(CategoryScale);

export function AlliancePopularity() {
    // Game Data
    // Using a state allows us to fetch this on mount and show a loaded beforehand
    const [dataReady, setDataReady] = useState(false);
    // Chart Data
    const [chartData, setChartData] = useState([]);

    const colours = [{
        label: "Chaos",
        colour:  "#CC6677"
    },
    {
        label: "Death",
        colour: "#88CCEE"
    },
    {
        label: "Destruction",
        colour: "#44AA99"
    },
    {
        label: "Order",
        colour: "#DDCC77"
    }
    ]

    console.log('Colours', colours);

    useEffect(() => {
        let timer1 = setTimeout(() => {
            fetchGameData();
        }, 500);

        return () => {
            clearTimeout(timer1);
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    let buildList = [];
    let content;

    const fetchGameData = async () => {

        const querySnapshot = await getDocs(collection(db, "/stats/matched/faction/"));
        querySnapshot.forEach((doc) => {
            // Use a standard array to organise the data
            buildList.push([doc.id, [doc.data()]]);
        });

        if (buildList.length === querySnapshot.docs.length) {
            setChartData({
                labels: buildList.map((data) => data[0]), 
                datasets: [
                    {
                    label: "Alliance Popularity",
                    data: buildList.map((data) =>  data[1][0].gamesPlayed),
                    backgroundColor: [
                        "#CC6677",
                        "#88CCEE",
                        "#44AA99",
                        "#DDCC77"
                    ],
                    borderColor: "white",
                    borderWidth: 2
                    }
                ]
            });
            setDataReady(true);
        }
    }

    if (dataReady) {
        content = (
            <Box className="stats-form alliance-stats" component={Paper} elevation={3}>  
            <div className="chart-container">
                <h3 style={{ textAlign: "center", marginBottom: "0" }}>Alliance Popularity</h3>
                <Doughnut
                    data={chartData}
                    options={{
                    plugins: {
                        title: {
                            display: false,
                        }
                    }
                    }}
                />
            </div>
            </Box>
        );

    } else {
        content = (
            <div className='fetching-data'><p>Fetching Data </p><CircularProgress /></div>
        );
    }

    return (
        <>
            {content}
        </>
    )

}

export function AllianceGameLength() {
    // Game Data
    // Using a state allows us to fetch this on mount and show a loaded beforehand
    const [dataReady, setDataReady] = useState(false);
    // Chart Data
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        let timer1 = setTimeout(() => {
            fetchGameData();
        }, 500);

        return () => {
            clearTimeout(timer1);
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    let buildList = [];
    let content;

    const fetchGameData = async () => {

        const querySnapshot = await getDocs(collection(db, "/stats/matched/faction/"));
        querySnapshot.forEach((doc) => {
            // Use a standard array to organise the data
            buildList.push([doc.id, [doc.data()]]);
        });

        if (buildList.length === querySnapshot.docs.length) {
            setChartData({
                labels: buildList.map((data) => data[0]), 
                datasets: [
                    {
                    data: buildList.map((data) =>  (data[1][0].turnsCompleted / data[1][0].gamesPlayed)),
                    backgroundColor: [
                        "#CC6677",
                        "#88CCEE",
                        "#44AA99",
                        "#DDCC77"
                    ],
                    borderColor: "white",
                    borderWidth: 1
                    }
                ]
            });
            setDataReady(true);
        }
    }

    if (dataReady) {
        content = (
            <Box className="stats-form alliance-stats" component={Paper} elevation={3}>  
            <div className="chart-container">
                <h3 style={{ textAlign: "center", marginBottom: "0" }}>Alliance Game Length</h3>
                <Bar
                    data={chartData}
                    options={{
                        plugins: {
                            title: {
                                display: false,
                                text: "What turn does a typical game end on for this alliance?"
                            },
                            legend: {
                                display: false
                            }
                        }
                    }}
                />
                <p>Game Length gives you an idea of how long this faction takes to complete a game.</p>
                <p>A low score here could indicate the following:</p>
                <ul>
                    <li>The power level of the army is high, so they beat opponents in earlier turns.</li>
                    <li>The power level of the army is low, so they are defeated in earlier turns.</li>
                    <li>The army struggles to reach turn 5 due to complexity or other factors.</li>
                </ul>
            </div>
            </Box>
        );

    } else {
        content = (
            <div className='fetching-data'><p>Fetching Data </p><CircularProgress /></div>
        );
    }

    return (
        <>
            {content}
        </>
    )

}

export function AllianceLeaderboard() {
    // Game Data
    // Using a state allows us to fetch this on mount and show a loaded beforehand
    const [dataReady, setDataReady] = useState(false);
    // Chart Data
    const [chartData, setChartData] = useState([]);
    const [horizontalChartData, setHorizontalChartData] = useState([]);

    useEffect(() => {
        let timer1 = setTimeout(() => {
            fetchGameData();
        }, 500);

        return () => {
            clearTimeout(timer1);
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    let buildList = [];
    let content;

    const fetchGameData = async () => {

        const querySnapshot = await getDocs(collection(db, "/stats/matched/faction/"));
        querySnapshot.forEach((doc) => {
            // Use a standard array to organise the data
            buildList.push([doc.id, [doc.data()]]);
        });

        if (buildList.length === querySnapshot.docs.length) {
            setChartData({
                labels: buildList.map((data) => data[0]), 
                datasets: [
                    {   
                        label: "Wins",
                        data: buildList.map((data) =>  (data[1][0].wins)),
                        backgroundColor: [
                            "#DDCC77"
                        ],
                        borderColor: "white",
                        borderWidth: 1,
                        stack: 'Stack 0'
                    }, 
                    {
                        label: "Losses",
                        data: buildList.map((data) =>  (data[1][0].losses)),
                        backgroundColor: [
                            "#CC6677",                            
                        ],
                        borderColor: "white",
                        borderWidth: 1,
                        stack: 'Stack 1'
                    },
                    {
                        label: "Draws",
                        data: buildList.map((data) =>  (data[1][0].draws)),
                        backgroundColor: [
                            "#44AA99",
                            
                        ],
                        borderColor: "white",
                        borderWidth: 1,
                        stack: 'Stack 2'
                    }
                ]
            });
            setHorizontalChartData({
                labels: buildList.map((data) => data[0]), 
                datasets: [
                    {   
                        label: "W/L/D Ratio",
                        data: buildList.map((data) =>  ((data[1][0].wins / data[1][0].gamesPlayed) * 100).toFixed(1)),
                        backgroundColor: [
                            "#DDCC77"
                        ],
                        borderColor: "white",
                        borderWidth: 1,
                    }
                ]
            });
            setDataReady(true);
        }
    }

    if (dataReady) {
        content = (
            <Box className="stats-form alliance-stats" component={Paper} elevation={3}>  
                <div className="chart-container">
                    <h3 style={{ textAlign: "center", marginBottom: "0" }}>Alliance Wins, Losses and Draws</h3>
                    <Bar
                        data={chartData}
                        options={{
                            plugins: {
                                title: {
                                    display: false,
                                    text: "What turn does a typical game end on for this alliance?"
                                },
                                legend: {
                                    display: true
                                },
                                datalabels: {
                                    formatter: (value, ctx) => {
                                      return;
                                    },
                                    color: '#fff',
                                },
                            },
                            scales: {
                                x: {
                                    stacked: true,
                                },
                                y: {
                                    stacked: true,
                                },
                            },
                        }}
                    />
                    
                    <h3 style={{ textAlign: "center", marginBottom: "0" }}>Alliance Wins rates %</h3>
                    <Bar
                        data={horizontalChartData}
                        options={{
                            indexAxis: 'y',
                            plugins: {
                                title: {
                                    display: false,
                                    text: "What turn does a typical game end on for this alliance?"
                                },
                                legend: {
                                    display: false
                                }
                            },
                            scales: {
                                x: {
                                    ticks: {
                                        // Include a dollar sign in the ticks
                                        callback: function(value, index, ticks) {
                                            return value + '%';
                                        }
                                    }
                                }
                            }
                        }}
                    />
                </div>
            </Box>
        );

    } else {
        content = (
            <div className='fetching-data'><p>Fetching Data </p><CircularProgress /></div>
        );
    }

    return (
        <>
            {content}
        </>
    )

}