import React, { useState } from "react";
import { ErrorBoundary } from 'react-error-boundary';
import { useStickyState } from "../utils/useStickyState";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import Players from './players/Players';
import GameType from './game/GameType';
import Armies from './armies/Armies';
import { MyFallbackComponent } from "../../utils/FallbackComponent";
import Button from '@mui/material/Button';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

export function Register({ uniqueUrl }) {
    const navigate = useNavigate();
    const [submitted, setFormData] = useState(false);
    const methods = useForm();
    const onSubmit = (data) => {        
        setFormData(true);
        console.log('Form Submitted / Data', data, submitted);
        navigate("/new-game/age-of-sigmar-" + uniqueUrl + "/game");
    }

    const [player1Name, setPlayer1Name] = useStickyState("", "Player 1 Name");
    const [player2Name, setPlayer2Name] = useStickyState("", "Player 2 Name");

    const onChangeSomeStatePlayer1 = (newPlayer1) => {
        setPlayer1Name(newPlayer1);
    };

    const onChangeSomeStatePlayer2 = (newPlayer2) => {
        setPlayer2Name(newPlayer2);
    };

    function clearGame() {
        localStorage.clear();
        navigate("/");
        console.log('Game Cleared, navigating to =>', uniqueUrl);
        window.location.reload();
    }

    return (
        <div>            
            <FormProvider {...methods} > 
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div>
                        <div className="selectors">
                            <ErrorBoundary
                                FallbackComponent={MyFallbackComponent}
                                onReset={() => {
                                    // reset the state of your app here
                                }}
                                resetKeys={['someKey']}
                            >
                                <GameType />
                            </ErrorBoundary>
                        </div>
                        <div className="selectors">
                            <ErrorBoundary
                                FallbackComponent={MyFallbackComponent}
                                onReset={() => {
                                    // reset the state of your app here
                                }}
                                resetKeys={['someKey']}
                            >
                                <Players player_1_name={player1Name} on_change_player_1_name={onChangeSomeStatePlayer1} player_2_name={player2Name} on_change_player_2_name={onChangeSomeStatePlayer2} uniqueUrl={"/new-game/age-of-sigmar-" + uniqueUrl} />
                            </ErrorBoundary>
                        </div>
                        <div className="selectors">
                            <ErrorBoundary
                                FallbackComponent={MyFallbackComponent}
                                onReset={() => {
                                    // reset the state of your app here
                                }}
                                resetKeys={['someKey']}
                            >
                                <Armies player_1_name={player1Name} player_2_name={player2Name}/>
                            </ErrorBoundary>
                        </div>
                    </div>
                    <div className="start-game">
                        <Button type="submit" variant="contained" size="large" aria-label="Start New Game" >Start New Game</Button>
                        <Button onClick={clearGame} value="Clear" variant="outlined" size="small" aria-label="Clear Form" color="primary" endIcon={<SyncProblemIcon />}>Clear Form</Button>
                    </div>
                </form>
            </FormProvider>
        </div>
    ) 
}
