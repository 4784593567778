import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; 
import { useStickyState } from "../../utils/useStickyState";
import { useFormContext } from "react-hook-form";
import { db } from '../../../firebase';
import { 
  getDocs, 
  query,
  collection
  } from "firebase/firestore"; 
import DefineLabel from "../../utils/DefineLabel";
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';

// Define the player name props so that we can assign "Player 1 / 2" when we import the component in App.js and apply this to the Label
export default function Belligerants({ player, nameChange, uniqueUrl }) {
    const referrer = uniqueUrl;
    // Define initial variables, with default option for form
    const [playerValue, setPlayer] = useStickyState('', player + ' Name');
    const [playerValue1, setPlayer1] = useStickyState('', 'Player 1 Name');
    const [user, setUser] = useState('');

    // Stated userlist to prevent re-write on component change or page refresh
    const [userList, setUserList] = useState([]);

    // Function required to bubble values from this form input up to the parent form in Register.js
    const { register } = useFormContext();
    // When the form element changes, we update the value in stickyState
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPlayer(event.target.value);
      nameChange(event.target.value);
    };

    const handleChange2 = (event: React.ChangeEvent<HTMLInputSelect>) => {
      setPlayer(event.target.innerText);
      nameChange(event.target.innerText); 
    };

    const handleChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPlayer1(event.target.value);
      nameChange(event.target.value);
    };

    const playerNumber = player.slice(-1);

    useEffect(() => {
      (async () => {
          const q = query(collection(db, "usernames"));
          const querySnapshot = await getDocs(q);
          let buildList = [];
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            buildList.push(doc.id);
          });
          setUserList(buildList);
      })();
      
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          setUser(user.displayName);
          setPlayer1(user.displayName);
          // ...
          console.log('user is signed in', user);
        } else {
          // User is signed out
          // ...
          console.log("user is logged out");
        }
      });

      return () => {
        // this now gets called when the component unmounts
      };

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // User logged in or not information
    const auth = getAuth();

    let content;

    if (player === 'Player 1') {
      if (user) {
        content = (
          <Box
            component="div"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
              <div>
                <FormControl sx={{ m: 1 }} variant="outlined">
                    <TextField
                        id="outlined-displayname"
                        type="username"
                        label={<DefineLabel player={player} parent='Belligerants.js' />}
                        value={playerValue1}
                        disabled={true}
                        required={true}
                        helperText={`Welcome Back ${user}`}
                        InputProps={{
                          endAdornment: <InputAdornment position="start"><PersonIcon /></InputAdornment>,
                        }}
                    />
                </FormControl>
              </div>
          </Box>
        )
      } else {
        content = (
            <Box
              component="div"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
              >   
                <div>
                  <small>Please <NavLink to="/login" state={{ path: referrer }}>
                        <Button value="Sign In" variant="outlined" size="small" aria-label="Sign In" color="primary" endIcon={<PersonIcon />}>Sign In</Button>
                      </NavLink> or enter a guest name</small>
                </div>

                  <FormControl sx={{ m: 1 }} variant="outlined">
                      <TextField
                          id="outlined-displayname"
                          type="username"
                          label={<DefineLabel player={player} parent='Belligerants.js' />}
                          value={playerValue1}
                          required={true}
                          helperText='Enter a guest name'
                          {...register('Player 1 Name', {
                            shouldUnregister: true,
                            onChange: handleChange3
                          })}
                      />
                  </FormControl>
            </Box>
        )
      }
    } else {
      content = (
        <Box
          component="div"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          
          <div>
            <Autocomplete
                id={playerNumber + 'name-select'}
                options={userList}
                autoHighlight
                freeSolo
                value={playerValue}
                onChange={handleChange2}
                getOptionLabel={(option) => (option ? option : '')}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={<DefineLabel player={player} parent='Belligerants.js' />}
                    onChange={handleChange}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
            />
          </div>
          <small>Search for a user or enter a guest name</small>
        </Box>
      )
    }
  
    return <div>{content}</div>;
}