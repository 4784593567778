import * as React from 'react';
import { useStickyState } from "../../utils/useStickyState";
import { useFormContext } from "react-hook-form";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import data from '../../../data/aos.json';
import Mission from './Mission';

export default function Season({gametype}) { 
    // Define initial variables, with default option for form
    const [season, setSeason] = useStickyState(data.season["GHB 2023-24"][0].name, 'Season');

    // Function required to bubble values from this form input up to the parent form in Register.js
    const { register } = useFormContext();
    // When the form element changes, we update the value in stickyState
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSeason(event.target.value);
    };

    let content; 
    if (gametype === "Matched Play") {
        content = (
            <Box
                component="div"
                sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        id="standard-select-season"
                        select
                        variant="standard"
                        name="Choose Season"
                        label="Pitched Battle season"
                        value={season}
                        required={true}
                        helperText={`Choose a season to determine missions`}
                        {...register('Season', {
                        shouldUnregister: true,
                        onChange: handleChange
                        })}
                    >
                            <MenuItem key={'GHB 22-23'} value={data.season["GHB 2022-23, S2"][0].name}>
                                {data.season["GHB 2022-23, S2"][0].name}
                            </MenuItem>
                            <MenuItem key={'GHB 23-24'} value={data.season["GHB 2023-24"][0].name}>
                                {data.season["GHB 2023-24"][0].name}
                            </MenuItem>
                    </TextField>
                </div>
                {/* Display Mission after selection */}
                <div className="selectors">
                    <Mission gametype={gametype} ghb={season} />
                </div>
            </Box>
        );
    }

    return <div>{content}</div>

}