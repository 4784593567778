import { NavLink } from "react-router-dom";
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

export default function NotFound() {
    return (
        <div className="missing-page">
            <h1>Oops! You seem to be lost.</h1>
            <p>Here are some helpful links:</p>

            <NavLink to={ "/" }>
                <Button value="Home" variant="outlined" size="small" aria-label="Home" color="primary">Home</Button>
            </NavLink>

            <NavLink to={ "/stats" }>
                <Button value="Stats Center" variant="outlined" size="small" aria-label="Stats Center" color="primary" endIcon={<QueryStatsIcon />}>Stats Center</Button>
            </NavLink>

            <NavLink to={ "/my-account" }>
                <Button value="My Account" variant="outlined" size="small" aria-label="My Account" color="primary" endIcon={<PersonIcon />}>My Account</Button>
            </NavLink>
        </div>
    )
}