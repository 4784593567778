import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; 
import { SignedIn } from './SignedIn';
import { Login } from './Login';
 
export function ManageAccount() {
    const [isLoggedin, setIsLoggedIn] = useState('');
    const [userUid, setUserUid] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [username, setUsername] = useState('');

    if (isLoggedin) {
        // console.log('User is logged in: ', isLoggedin, username);
    }

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                setUserUid(user.uid);
                setUserEmail(user.email);
                setIsLoggedIn(!!user);
                setUsername(user.displayName);
                // ...
                
            } else {
                // User is signed out
                // ...
            }
        });
    }, [])
 
    return (
        <div>                                            
            <h1>Manage Account</h1>
            <p>Your account will enable you to track past and future games.</p>
            {isLoggedin ? (<SignedIn uniqueUid={userUid} un={username} uniqueEmail={userEmail}/>) : (<Login referrer="/my-account" />)}                    
        </div>
    )
}