import React from "react";
import { useStickyState } from "../../utils/useStickyState";
import { useFormContext } from "react-hook-form";
import DefineLabel from "../../utils/DefineLabel";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

// Define the player name props so that we can assign "Player 1 / 2" when we import the component in App.js and apply this to the Label
export default function SubFaction2({ player, faction, subFactionTitle, subFaction }) {
    // Define initial variables, with default option for form
    const [subfaction, setSubFaction] = useStickyState('', player + ' Chosen Sub Faction');
    // Function required to bubble values from this form input up to the parent form in Register.js
    const { register } = useFormContext();
    // When the form element changes, we update the value in stickyState
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubFaction(event.target.value);
    };
    
    let content;
    if (faction) {
        content = (
            <Box
                component="div"
                sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                <TextField
                    id="standard-select-subfaction1"
                    select
                    variant="standard"
                    name={player}
                    label={<DefineLabel player={player} parent='SubFaction.js' />}
                    value={subfaction}
                    required={false}
                    helperText={`Select ${subFactionTitle}`}
                    {...register(player + ' Sub Faction', {
                    shouldUnregister: true,
                    onChange: handleChange
                    })}
                >
                    {Object.keys(subFaction).map((option, i) => (
                        <MenuItem key={i} value={subFaction[option]}>
                            {subFaction[option]}
                        </MenuItem>
                    ))}
                </TextField>
                </div>
            </Box>
        );
    } else {

    }
    return <div>{content}</div>;
}        