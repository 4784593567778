// Delete me when you delete data console log. 
import React from "react";

const TotalOpen = ({ player, uurl, defaultData }) => {
    let playerName = player.replace(/\s+/g,'').toLowerCase();
    // Define the total vps from all of the settings in local storage. 
    let totalVps = defaultData[playerName].score.total;
    // Handle the toggle of the checkbox
    
    return (
        <>  
            <div className="totals">
                <p><strong>Total: &nbsp;</strong> {totalVps}</p>
            </div>
        </>
    )

}
export default TotalOpen;

