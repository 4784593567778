import React from 'react';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ChangeLog() {
 
    return (
        <div className="body privacy-policy">
            <h1>What's New?</h1>

            <h3>24/07/2023: Response times and Game Ended Page</h3>
            <p>Reintroduced local storage methods to shorten database request time. </p>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography><InfoIcon/>Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                        <ol>
                            <li>Added localStorage for registration only, shortening database request times for performance on slower internet connections.</li>
                            <li>Added data and graphs to Game Ended Page.</li>
                        </ol>
                </AccordionDetails>
            </Accordion>

            <Divider />

            <h3>22/07/2023: iOS</h3>
            <p>Major refactor of Registration and Scoring functionality to improve iOS stability</p>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography><InfoIcon/>Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                        <ol>
                            <li>Pivoted away from localStorage as a method of storing user choices to guard against crashes or page refreshes.</li>
                            <li>iOS devices cap localStorage cache size for performance reasons, so after a series of games this app is likely to fail as a result.</li>
                            <li>Error boundaries added around nested scoring components to guard against failures</li>
                        </ol>
                </AccordionDetails>
            </Accordion>

            <Divider />

            <h3>12/07/2023: Homepage Game Data</h3>
            <p>Sunsetted part of database that controlled this and replaced with a more resource efficient method</p>

            <Divider />

            <h3>12/07/2023: GHB 2023/2024</h3>
            <p>Added the new GHB data to the app, along with some minor improvements. </p>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography><InfoIcon/>Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                        <ol>
                            <li>GHB version dropdown added to /new-game under Matched Play.</li>
                            <li>GHB 2023/2024 Missions Added.</li>
                            <li>GHB 2023/2024 Battle Tactics Added.</li>
                            <li>GHB 2023/2024 Grand Strategies Added.</li>
                        </ol>
                </AccordionDetails>
            </Accordion>

            <Divider />

            <h3>30/06/2023: Stats center expanded</h3>
            <p>Fixed some rendering errors and began to add more data</p>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography><InfoIcon/>Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                        <ol>
                            <li>Instances where Not a Number (NaN) is present where no data is found replaced with "0".</li>
                            <li>Structured user data for easier retrieval.</li>
                        </ol>
                </AccordionDetails>
            </Accordion>

            <Divider />

            <h3>30/06/2023: Stats Center Improvements and Resume Game added</h3>
            <p>Addressing readability, accessability and mobile experience.</p>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography><InfoIcon/>Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                        <ol>
                            <li>Added Mobile Styles for mobile users.</li>
                            <li>Labelling of charts for mobile readability</li>
                            <li>Temporarily disabled Narrative and Open until stats are available</li>
                            <li>Colour blind friendly palette.</li>
                            <li>Resume Game added to home as an easy way back if your browser restarts.</li>
                            <li>End Game prompt added to home.</li>
                            <li>UX improvements added to signed in.</li>
                        </ol>
                </AccordionDetails>
            </Accordion>

            <Divider />

            <h3>29/06/2023: Sign in with Popup</h3>
            <p>Sunsetted Sign in with Redirect due to lack of support from google, leading to 404's in the user journey.</p>

            <Divider />

            <h3>28/06/2023: Delete account functionality</h3>
            <p>Option to delete account and data added to User Management. Contact email added for bug reporting.</p>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography><InfoIcon/>Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                        <ol>
                            <li>Added delete button and confirm modal.</li>
                            <li>Added 404 page.</li>
                            <li>Removed Google Sign In Method due to it occasionally 404ing.</li>
                            <li>Contact information added.</li>
                            <li>Footer added with Social Links.</li>
                        </ol>
                </AccordionDetails>
            </Accordion>

            <Divider />

            <h3>26/06/2023: UI Improvements to User Management</h3>
            <p>Errors would appear when your username and email are set, telling you they were already taken</p>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography><InfoIcon/>Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                        <ol>
                            <li>Country selector no longer returns undefined.</li>
                            <li>Username no longer tells you it is taken if it is equal to your current username.</li>
                            <li>Email no longer tells you it is taken if it is equal to your current email.</li>
                            <li>Changing usernames now no longer deletes your data.</li>
                            <li>Player 1 submit array now tracks wins, losses and draws accurately.</li>
                            <li>Game Submit for Matched no longer double submits Grand Strategy.</li>
                        </ol>
                </AccordionDetails>
            </Accordion>

            <Divider />

            <h3>23/06/2023: Performance Monitoring Tools added</h3>
            <p>Errors have been reported for mobile users. This offers a sample of data of what these common errors are. No changes to UI.</p>

            <Divider />

            <h3>05/06/2023: Narrative and Open custom scoresheets</h3>
            <p>Previously, the scoresheet required Grand Strategy and Battle Tactics choices. These have been stripped out for open and narrative play.</p>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography><InfoIcon/>Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                        <ol>
                            <li>Bespoke functionality to handle database setting of Narrative and Open results.</li>
                        </ol>
                </AccordionDetails>
            </Accordion>

            <Divider />

            <h3>03/06/2023: User Journey Improvements</h3>
            <p>Initial user testing revealed confusion when ending a game, starting a new game or navigating away from the game in progress.</p>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography><InfoIcon/>Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                        <ol>
                            <li>Games Played section added to allow user to navigate back to game in progress.</li>
                            <li>Games Played section added to allow user to clear game in progress and start another.</li>
                            <li>End Game updated to redirect to new page and clear local storage, ready for a new game.</li>
                        </ol>
                </AccordionDetails>
            </Accordion>

            <Divider />
        </div>
    );
}