import React, { useState, useEffect } from "react";
import { ErrorBoundary } from 'react-error-boundary'
import { NavLink, useNavigate } from "react-router-dom";
import { db } from "./firebase";
import { setDoc, doc, getDoc } from "firebase/firestore"; 
import Update from "./changelog/Update";
import LogoutButton from './auth/LogoutButton';
import Button from '@mui/material/Button';
import ChangeLog from './changelog/ChangeLog';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import CasinoIcon from '@mui/icons-material/Casino';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { GamesPlayed } from './stats/GamesPlayed';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import { Paper } from "@mui/material";
import { AllianceGameStats, AllianceLeaderboard } from "./stats/Matched";
// import { MatchedGroupedBarChart } from "./stats/MatchedGroupedBarChart";
import { MyFallbackComponent } from "./utils/FallbackComponent";

export function Home({uniqueUrl}) {
    const navigate = useNavigate();
    const [gameFound, setGameFound] = useState(false);
    const [gameData, setGameData] = useState([]);
    console.log('Game Found', gameFound);

    // Check for game in progress once. 
    useEffect(() => {
        checkForGame();
    }, [gameFound]) // eslint-disable-line react-hooks/exhaustive-deps

    const checkForGame = async () => {
        const docRef = doc(db, "games", uniqueUrl);
        const docCheck = await getDoc(docRef);
        console.log('fetchData:', docCheck);
        if (docCheck.exists()) {
            if (!docCheck.data().complete) {
                // Game is still in progress as `complete: false`;
                setGameData(docCheck.data());
                setGameFound(true);
            } else {
                // Game is finished as `complete: true`;
            }
            
        } else {
            setGameFound(false);
        }

    }

    const clearGame = async() => {
        await setDoc(doc(db, "games", uniqueUrl), {
            complete: true
        }, { merge: true });
        setGameFound(false);
        localStorage.clear();
    }

    function resumeGame() {
        navigate("/new-game/age-of-sigmar-" + uniqueUrl + "/game");
    }


    return (
        <div className="body">
            <Update />
            <h1>What is it?</h1>
            <p>Scorehammer is a simple Age of Sigmar scoring app, enabling you to track your Narrative, Open and Matched Play games.</p>
            <p>You can play as a guest. Or you can register for an account in order to keep track of the games that you have played.</p>
            <p>If you would like to participate, please take a read of our <NavLink to={ "/privacy-policy" }>Privacy Policy</NavLink> to find out how we handle the data you submit.</p>
            
            <div>      
                <NavLink to={ "/new-game/age-of-sigmar-" + uniqueUrl }>
                    <Button value="New Game" variant="outlined" size="large" aria-label="New Game" color="primary" endIcon={<CasinoIcon />}>New Game</Button>
                </NavLink>
            </div>
            <NavLink to="/login" state={{ path: '/' }}>
                <Button value="Login" variant="outlined" size="small" aria-label="Login" color="primary" endIcon={<PersonIcon />}>Login</Button>
            </NavLink>
            {' '}
            <NavLink to="/signup" state={{ path: '/' }}>
                <Button value="Sign up" variant="outlined" size="small" aria-label="Sign up" color="primary" endIcon={<PersonAddIcon />}>Sign up</Button>
            </NavLink>
            {' '}
            <LogoutButton />

            <h1>Games in progress</h1>

            <ErrorBoundary
                FallbackComponent={MyFallbackComponent}
                onReset={() => {
                    // reset the state of your app here
                }}
                resetKeys={['someKey']}
            >
            {gameFound ? 
                <>
                    <Box>
                        <Button onClick={resumeGame} value="Clear" variant="contained" size="medium" aria-label="Clear Form" color="primary" endIcon={<PlayCircleIcon />}>{"Return to: " + gameData.player1.name + ' VS ' + gameData.player2.name}</Button>
                        <Box component={Paper} elevation={3} className="resume-game-info">
                            <Typography variant="caption"><InfoIcon/> If you see a game here that you have already finished, please click through and hit End Game to submit the data for tracking.</Typography>
                        </Box>
                    </Box>
                    <Divider className="resume-game-divider"/>
                    <Box>
                        <Button onClick={clearGame} value="Clear" variant="outlined" size="small" aria-label="Clear Form" color="primary" endIcon={<SyncProblemIcon />}>Clear Game</Button>
                    </Box>
                </>
                : "No Games in progress"}
            </ErrorBoundary>

            <ErrorBoundary
                FallbackComponent={MyFallbackComponent}
                onReset={() => {
                    // reset the state of your app here
                }}
                resetKeys={['someKey']}
            >
                <GamesPlayed />
            </ErrorBoundary>
            <ErrorBoundary
                FallbackComponent={MyFallbackComponent}
                onReset={() => {
                    // reset the state of your app here
                }}
                resetKeys={['someKey']}
            >
                <AllianceLeaderboard />
            </ErrorBoundary>
            <ErrorBoundary
                FallbackComponent={MyFallbackComponent}
                onReset={() => {
                    // reset the state of your app here
                }}
                resetKeys={['someKey']}
            >
                <AllianceGameStats />
            </ErrorBoundary>
            <p>See more in our <NavLink to="/stats">Stats Center</NavLink></p>
            {/* <MatchedGroupedBarChart /> */}
        </div>
    );
}

export function Changelog({uniqueUrl}) {
    return (
        <div className="body">
            <ChangeLog />
        </div>
    );
}
