import React from "react";
import { useFormContext } from "react-hook-form";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Input from "./Input";

const TurnOpen = ({ player, turn, defaultData }) => {
// Define initial variables, with default option for form
const { register } = useFormContext();
    return (
        <TableRow
        key={turn}
        className={"turn-" + turn}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell className="turn"><p>{turn}</p></TableCell>
            <TableCell size="small" align="center">
                <Input player={player} turn={turn} value="VPs" totalScore={0} defaultData={defaultData}/>
            </TableCell>
        </TableRow>
    )

}
export default TurnOpen;

