import React from "react";
import { useStickyState } from "../../utils/useStickyState";
import { useFormContext } from "react-hook-form";
import DefineLabel from "../../utils/DefineLabel";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Faction from "./Faction";
import data from '../../../data/aos.json';

// Define the player name props so that we can assign "Player 1 / 2" when we import the component in App.js and apply this to the Label
export default function Army({ player }) {
    // Define the scope of the array to take data from
    const grandAlliance = data.grandAlliances; 
    // Define initial variables, with default option for form
    const [alliance, setAlliance] = useStickyState('', player + ' Grand Alliance');
    const { register } = useFormContext();
    // When the form element changes, we update the value in stickyState
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAlliance(event.target.value);
    };

    return (
      <Box
        component="div"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id="standard-select-grandalliance2"
            select
            variant="standard"
            name={player}
            label={<DefineLabel player={player} parent='Army.js' />}
            value={alliance}
            required={true}
            helperText="Select Grand Alliance"
            {...register(player + ' Grand Alliance', {
              shouldUnregister: true,
              onChange: handleChange
            })}
          >
            {Object.entries(grandAlliance).map(option => (
                <MenuItem key={option[1].name} value={option[1].name}>
                    {option[1].name}
                </MenuItem>
            ))}
          </TextField>
          <Faction player={player} alliance={alliance} />
        </div>
      </Box>
    );
}