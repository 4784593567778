import * as React from 'react';
import { useStickyState } from "../../utils/useStickyState";
import { useFormContext } from "react-hook-form";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import data from '../../../data/aos.json';

export default function Mission({gametype, ghb}) { 
    // Define initial variables, with default option for form
    const [mission, setMission] = useStickyState('', 'Mission');
    // Function required to bubble values from this form input up to the parent form in Register.js
    const { register } = useFormContext();
    // When the form element changes, we update the value in stickyState
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMission(event.target.value);
    };

    let content; 
    if (gametype === "Matched Play") {
        content = (
            <Box
                component="div"
                sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        id="standard-select-mission"
                        select
                        variant="standard"
                        name="Choose game type"
                        label="Mission"
                        value={mission}
                        required={true}
                        helperText={`Select Mission`}
                        {...register('Mission', {
                        shouldUnregister: true,
                        onChange: handleChange
                        })}
                    >
                        {Object.keys(data.season[ghb][0].missions).map((option, i) => (
                            <MenuItem id="missions" key={i} value={data.season[ghb][0].missions[option]}>
                                {data.season[ghb][0].missions[option]}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </Box>
        );
    }

    return <div>{content}</div>

}