import React from "react";
import { useLocation } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export function GameEnded(uniqueUrl) {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    // Tabs
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const location = useLocation();
    // Get Game Data
    let data = location.state.data;
    let uniqueUrlTrimmed = location.state.uniqueUrl;
    console.log('GameEnded.js => ', uniqueUrl.uniqueUrl, data, uniqueUrlTrimmed);

    // Chart Data
    // const [chartData, setChartData] = useState([]);
    
    const colours = ["#CC6677", "#88CCEE"]
    const labels = ["t1", "t2", "t3", "t4", "t5"]

    const player1Score = [
        Number(data.player1.score.t1),
        Number(data.player1.score.t2),
        Number(data.player1.score.t3),
        Number(data.player1.score.t4),
        Number(data.player1.score.t5),
    ];

    console.log('GameEnded.js => player1Score: ', player1Score, player1Score.map((data) => data));

    const player2Score = [
        Number(data.player2.score.t1),
        Number(data.player2.score.t2),
        Number(data.player2.score.t3),
        Number(data.player2.score.t4),
        Number(data.player2.score.t5),
    ];


    const graphData = {
        labels: labels,
        datasets: [
          {
            label: 'Player 1',
            data: player1Score,
            borderColor: colours[0],
          },
          {
            label: 'Player 2',
            data: player2Score,
            borderColor: colours[1],
          }
        ]
      };

    return (
        <div className="body game-ended">
            <Box sx={{ width: '100%' }}>
            
                <p>Game: <strong>{uniqueUrlTrimmed}</strong> ended.</p>
                <h2>{data.player1.name} vs {data.player2.name}</h2>
                <h3>{data.player1.score.total} - {data.player2.score.total}</h3>
                
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                    <Tab label={data.player1.name} {...a11yProps(0)} />
                    <Tab label={data.player2.name} {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0} className="table">
                    <TableContainer component={Paper} className="mobileScoresheet">
                        <Table size="small" aria-label="a dense table">
                            <TableBody>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Alliance</StyledTableCell>
                                    <StyledTableCell>{data.player1.alliance}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Faction</StyledTableCell>
                                    <StyledTableCell>{data.player1.faction}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Sub Faction</StyledTableCell>
                                    <StyledTableCell>{data.player1.subfaction}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Grand Strategy</StyledTableCell>
                                    <StyledTableCell className="strat-right">{data.player1.grandstrategy.chosen} {data.player1.grandstrategy.achieved ? <CheckCircleIcon color="success"/> : <CancelIcon />}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Tactic t1</StyledTableCell>
                                    <StyledTableCell className="strat-right">{data.player1.battletactics.t1.chosen} {data.player1.battletactics.t1.achieved ? <CheckCircleIcon color="success"/> : <CancelIcon />}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Tactic t2</StyledTableCell>
                                    <StyledTableCell className="strat-right">{data.player1.battletactics.t2.chosen} {data.player1.battletactics.t2.achieved ? <CheckCircleIcon color="success"/> : <CancelIcon />}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Tactic t3</StyledTableCell>
                                    <StyledTableCell className="strat-right">{data.player1.battletactics.t3.chosen} {data.player1.battletactics.t3.achieved ? <CheckCircleIcon color="success"/> : <CancelIcon />}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Tactic t4</StyledTableCell>
                                    <StyledTableCell className="strat-right">{data.player1.battletactics.t4.chosen} {data.player1.battletactics.t4.achieved ? <CheckCircleIcon color="success"/> : <CancelIcon />}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Tactic t5</StyledTableCell>
                                    <StyledTableCell className="strat-right">{data.player1.battletactics.t5.chosen} {data.player1.battletactics.t5.achieved ? <CheckCircleIcon color="success"/> : <CancelIcon />}</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1} className="table">
                    <TableContainer component={Paper} className="mobileScoresheet">
                        <Table size="small" aria-label="a dense table">
                            <TableBody>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Alliance</StyledTableCell>
                                    <StyledTableCell>{data.player2.alliance}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Faction</StyledTableCell>
                                    <StyledTableCell>{data.player2.faction}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Sub Faction</StyledTableCell>
                                    <StyledTableCell>{data.player2.subfaction}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Grand Strategy</StyledTableCell>
                                    <StyledTableCell className="strat-right">{data.player2.grandstrategy.chosen} {data.player2.grandstrategy.achieved ? <CheckCircleIcon color="success"/> : <CancelIcon />}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Tactic t1</StyledTableCell>
                                    <StyledTableCell className="strat-right">{data.player2.battletactics.t1.chosen} {data.player2.battletactics.t1.achieved ? <CheckCircleIcon color="success"/> : <CancelIcon />}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Tactic t2</StyledTableCell>
                                    <StyledTableCell className="strat-right">{data.player2.battletactics.t2.chosen} {data.player2.battletactics.t2.achieved ? <CheckCircleIcon color="success"/> : <CancelIcon />}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Tactic t3</StyledTableCell>
                                    <StyledTableCell className="strat-right">{data.player2.battletactics.t3.chosen} {data.player2.battletactics.t3.achieved ? <CheckCircleIcon color="success"/> : <CancelIcon />}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Tactic t4</StyledTableCell>
                                    <StyledTableCell className="strat-right">{data.player2.battletactics.t4.chosen} {data.player2.battletactics.t4.achieved ? <CheckCircleIcon color="success"/> : <CancelIcon />}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell className="left" align="left" size="small">Tactic t5</StyledTableCell>
                                    <StyledTableCell className="strat-right">{data.player2.battletactics.t5.chosen} {data.player2.battletactics.t5.achieved ? <CheckCircleIcon color="success"/> : <CancelIcon />}</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CustomTabPanel>
                

                <Divider/>
                <h3>Stats</h3>
                <Line
                    data={graphData}
                    options={{
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: 'Score Gradient'
                            }
                        },
                        scales: {
                            y: {
                                title: {
                                  display: true,
                                  text: 'Score'
                                },
                                min: 0,
                                ticks: {
                                  // forces step size to be 50 units
                                  stepSize: 1
                                }
                              }
                        }
                    }}
                />
            </Box>
        </div>
    )
}