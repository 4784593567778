// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { 
  getAuth,
 } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAuEkH8xZkLCSyUzNgxCuF72wUsrMCm3pE",
  authDomain: "scorehammer.firebaseapp.com",
  databaseURL: "https://scorehammer-age-of-sigmar-default.europe-west1.firebasedatabase.app/",
  projectId: "scorehammer",
  storageBucket: "scorehammer.appspot.com",
  messagingSenderId: "869102958227",
  appId: "1:869102958227:web:07171846efd621f58a7f66",
  measurementId: "G-VKL8M207JP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const database = getDatabase(app);

console.log('Firebase Initialised', app, db, auth, database)

export { 
  database,
  auth,
  db,
  app,
};
