import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from "firebase/firestore"; 
import { db } from '../firebase';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export function GamesPlayed() {
    // Game Data
    // Using a state allows us to fetch this on mount and show a loaded beforehand
    const [gameData, setGameData] = useState([]);
    const [dataReady, setDataReady] = useState(false);

    useEffect(() => {
        let timer1 = setTimeout(() => {
            fetchGameData();
        }, 500);

        return () => {
            clearTimeout(timer1);
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchGameData = async () => {
        const statsRef = collection(db, 'stats');

        const q = query(statsRef, where("gamesPlayed", ">=", 0));
        let emptyArray = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let newArray = [doc.id, doc.data().gamesPlayed];
            emptyArray.push(newArray)
        });

        setGameData(emptyArray);
        setDataReady(true);
    }

    let content;

    if (dataReady) {
        content = (
            <>
                <TableRow hover>
                    <TableCell>
                        {gameData[1][1] + gameData[2][1] + gameData[0][1]}
                    </TableCell>
                    <TableCell>
                        {gameData[2][1]}
                    </TableCell>
                    <TableCell>
                        {gameData[0][1]}
                    </TableCell>
                    <TableCell>
                        {gameData[1][1]}
                    </TableCell>
                </TableRow>
            </>
        )
    } else {
        content = (
            <>
                <TableRow>
                    <TableCell className='fetching-data'>
                        Fetching Data <CircularProgress />
                    </TableCell>
                </TableRow>
            </>
        )
    }


    return (
        <Box sx={{ width: '100%' }}>  
        <Divider variant="middle" className="stats-divider" />
        <h1>Stats <QueryStatsIcon/></h1>                                     
            <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: 440 }} className="stats-form">
                <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={4}>
                            Games Played
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Total
                        </TableCell>
                        <TableCell>
                            Matched
                        </TableCell>
                        <TableCell>
                            Open
                        </TableCell>
                        <TableCell>
                            Narrative
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {content}
                </TableBody>
                </Table>
            </TableContainer>
            </Paper>
        </Box>
    )
}