import React from 'react';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function PrivacyPolicy() {
 
    return (
        <div className="body privacy-policy">
            <h1>Privacy Policy</h1>
            <p>There are two main aims of Scorehammer. And by signing up for an account you agree to the data you provide us being used for both purposes.</p>
            <ol>
                <li>To track aggregated data for all games played and to provide those stats so people can view trends within the different game systems.</li>
                <Divider />
                <li>To allow users to track the games they have played and to see how they perform specifically.</li>
            </ol>
            <Divider />

            <h3>Aggregated Data (non-personalised)</h3>
            <p>This is everything you submit for you and your opponent on both the New Game and Scoresheet Forms.</p>
            <p>Whenever a player hits the <strong>End Game</strong> button, this data is sent to our <strong>Games</strong> database where it is categorised as being a game between two factions. This data is non-personally identifiable. It's as if Greyfyrd Fyreslayers had a fight with Jaws of Mork Gloomspite Gitz, and the two players, Michael and Sarah had nothing to do with it.</p>
            <p>From this pool of data our Stats page is built. </p>

            <Divider />
            <h3>Personalised Data</h3>
            <p>This combines the sign up information you have provided as well as the games you have played.</p>
            <p>When you sign up for an account, you provide us with your <strong>Email Address</strong>. You will also be redirected to our <strong>Manage Account</strong> page where you should specify a <strong>Username</strong> and an optional <strong>Region or Country</strong></p>
            <p>Your <strong>Username</strong> is searchable for other players on the New Game form. It allows two registered players to both add the results of their game to their respective personal stats database.</p>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography><InfoIcon/> Searchable Player Rankings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        At a later date, this app will have the functionality to search for a users game statistics too. For the purposes of comparing one player to another or for building leaderboards. When this functionality is rolled out, the ability to opt in will be added to the <strong>Manage Account</strong> section of the app, and you will be notified via a popup on your next visit to set this value to <em>True</em> if you wish to become ranked and searchable in this way.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            
            <p><strong>Region or Country</strong> is used in the <strong>Aggregated Database</strong> as a way of sorting game data by different regions. It's useful when it comes to see how the meta of the game differs from place to place.</p>

            <p><strong>Games you have played</strong> will be visible on your stats page. This data is a repeat of the aggregated data you have sent, but it includes information about your opponent and the time and date of the game. With this data, you can see where you rank on the global leaderboards for each particular choice you make in game.</p>

            <Divider />
            <h3>What happens if I close my account?</h3>
            <p>If you decide you'd like to close your account, all of your <strong>Personalised Information</strong> will be deleted. This includes:</p>
            <ol>
                <li>Your Email Address</li>
                <Divider />
                <li>Your Username</li>
                <Divider />
                <li>Your Region</li>
                <Divider />
                <li>All of the games in your stats section</li>
            </ol>

            <h4>What about Aggregated Data</h4>
            <p>As there is nothing linking the games you have played in your Stats Section to the data that you have contributed to the Aggregated Data, nothing will be deleted from the Aggregated Data</p>
            <p>If you had played 20 games with Fyreslayers and you deleted your account, the number of Games Played for the Fyreslayers Faction wouldn't suddenly drop by 20.</p>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography><InfoIcon/> Searchable Player Rankings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        If you have opted in to Searchable Player Rankings when this feature is rolled out, the deletion of your account would mean the deletion of your username from the leaderboards.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <h4>What about future games</h4>
            <p>The next time an opponent creates a game and searches for your old username in the opponents field, they will not be able to find you.</p>

            <h4>Can I retrive my account</h4>
            <p>At the moment no. In the future I may explore the ability to add a Pause Account section but for the time being, please consider account deletion to be permanent.</p>

            <h4>What happens to my Username?</h4>
            <p>As usernames are unique, when you delete your account you also delete your username, freeing it up for another player to claim.</p>
            
        </div>
    );
}