import React, { useState } from "react";
import { useStickyState } from "../utils/useStickyState"
import { useFormContext } from "react-hook-form";
import { arrayPrune } from "../utils/arrayPrune";
import { db } from "../../firebase";
import { setDoc, doc } from "firebase/firestore"; 
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import data from '../../data/aos.json';

// Define the player name props so that we can assign "Player 1 / 2" when we import the component in App.js and apply this to the Label
export default function GrandStrategy({ player, faction, alliance, season, uurl, defaultData }) {
    let selectedFactionAsString = faction.replace(/\s+/g,'').toLowerCase();
    // Todos: Update Turn and Player to not require this change in every consuming component. 
    let playerName = player.replace(/\s+/g,'').toLowerCase();
    const selectedAlliance = arrayPrune(data.grandAlliances, alliance[0]);
    const grandStrats = selectedAlliance.factions[selectedFactionAsString].grandStrategies;
    // Define initial variables, with default option for form
    const [gStrats, setStrats] = useStickyState('', player + ' Grand Strategy');
    // Function required to bubble values from this form input up to the parent form in Register.js
    const { register } = useFormContext();
    // When the form element changes, we update the value in stickyState
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStrats(event.target.value);
        updateGrandStrat(event.target.value);
    };

    // Update the strategy in the document to protect against refreshes.
    const updateGrandStrat = async (e) => {
        await setDoc(doc(db, `games/${uurl}`), {
            [playerName]: {
                grandstrategy: {
                    chosen: e,
                }
            },
        }, { merge: true });

        // Set the strategy in defaultData to manage state in this component. 
        defaultData[playerName].grandstrategy.chosen = e;
    }


    return (
        <div className="grand-strat">
              <TextField
                id="standard-select-grandstrats1"
                select
                name={player}
                variant="standard"
                label={player}
                value={gStrats}
                helperText={`Select Grand Strategy`}
                {...register(player + ' Grand Strategy', {
                  shouldUnregister: true,
                  onChange: handleChange
                })}
              >
                <p>Faction Specific</p>
                {Object.keys(grandStrats).map((option, i) => (
                    <MenuItem key={i} value={grandStrats[option]}>
                        {grandStrats[option]}
                    </MenuItem>
                ))}
                <p>Generals Handbook</p>
                {Object.keys(data.season[season][0].grandStrategies).map((option, i) => (
                    <MenuItem key={i} value={data.season[season][0].grandStrategies[option]}>
                        {data.season[season][0].grandStrategies[option]}
                    </MenuItem>
                ))}
              </TextField>
        </div>
    )
}    