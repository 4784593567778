import React from "react";
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

export function NavBar({uniqueUrl}) {
    return (
        <div className="navbar">
            <Link to="/"><HomeIcon /></Link>
            <Link to={ "/new-game/age-of-sigmar-" + uniqueUrl}>New Game</Link>
            <Link to={ "/stats" }><QueryStatsIcon /></Link>
            <Link to={ "/my-account"}><ManageAccountsIcon /></Link>
        </div>
    );
}