import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { 
createUserWithEmailAndPassword,
getAuth,
onAuthStateChanged,
updateProfile,
GoogleAuthProvider,
signInWithPopup,
fetchSignInMethodsForEmail,
sendEmailVerification
} from 'firebase/auth';
import { 
getDoc,
setDoc,
doc
} from "firebase/firestore"; 
import { db } from '../firebase';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import GoogleIcon from '@mui/icons-material/Google';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export function Signup() {
    let location = useLocation();
    let pathBack;
    if (location.state) {
        pathBack = location.state.path;
    } else {
        pathBack = '/'
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    };

    let [validUsername, setValidUsername] = React.useState(false);
    let [validEmail, setValidEmail] = React.useState(false);

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');

    const auth = getAuth();

    useEffect(() => {
        userNameValidator();
        emailValidator();
    }, [username, email, auth]) // eslint-disable-line react-hooks/exhaustive-deps

    // This enables us to through an error and disable the submit button if the username is taken.
    const userNameValidator = async () => {
        const docRef = doc(db, `usernames/${username}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setValidUsername(false);
        } else {
            setValidUsername(true);
        }
    }

    // This allows us to disable the submit button and throw and error if the email is taken. 
    const emailValidator = () => {
        fetchSignInMethodsForEmail(auth, email).then((result) => {
            if (result.length) {
                setValidEmail(false);
            } else {
                setValidEmail(true);
            }
        });
    }

    const provider = new GoogleAuthProvider();
    const signInWithGoogle = async () => {
        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            // IdP data available using getAdditionalUserInfo(result)
            console.log('User => ', user.displayName);
            // ...
            navigate('/my-account');
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          console.log('Sign In with Google Error => ', errorCode, 'Message => ', errorMessage, 'Email: ', email, credential);
          // ...
        });
    };

    const onSubmit = async () => {
     
      await createUserWithEmailAndPassword(auth, email, password, username)
        .then((userCredential) => {
            // Signed in
            const auth = getAuth();
            updateProfile(auth.currentUser, {
                displayName: username
              }).then(() => {
                // Profile updated!
                // ...
              }).catch((error) => {
                // An error occurred
                // ...
            });
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User
                    console.log('User is now registered: ', user);
                    sendEmailVerification(auth.currentUser)
                    .then(() => {
                        // Email verification sent!
                        // ...
                        console.log('Email verification sent to: ', auth.currentUser);
                    });
                }
            });
            navigate("/my-account")
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            // ..
        });
    }

    const addToDb = async (e) => {
        e.preventDefault();
        const docRef = doc(db, "usernames", username);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            alert('Username Taken');
        } else {
            // Create the new username
            await setDoc(doc(db, `usernames/${username}`), {
                owner: ''
            });
            onSubmit();
        }
    }

    return (
        <div>                  
            <h1>Create your Scorehammer Account</h1>
            <p>Your account will enable you to track your games.</p> 
            <p>By creating an account you are agreeing to our <NavLink to={ "/privacy-policy" }>Privacy Policy</NavLink></p> 
            <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} centered aria-label="basic tabs example">
                    <Tab label={<PersonAddIcon />} {...a11yProps(0)} />
                    <Tab label={<GoogleIcon />} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <div>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <TextField
                            id="outlined-displayname"
                            error={validUsername ? false : true }
                            type="username"
                            label="Username"
                            value={username}
                            required={true}
                            helperText={validUsername ? 'Username is available' : 'Username is taken'}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </FormControl>
                </div>

                <div>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <TextField
                            id="outlined-email"
                            type="email"
                            label="Email"
                            error={validEmail ? false : true }
                            value={email}
                            required={true}
                            helperText={validEmail ? 'Email is available' : 'Email is in use'}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormControl>
                </div>

                <div>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <TextField
                            id="outlined-adornment-password"
                            type="password"
                            label="Password"
                            value={password}
                            required={true}
                            helperText="Choose a password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </FormControl>
                </div>                                             
                
                <Button onClick={addToDb} disabled={(validUsername ? false : true) || (validEmail ? false : true)} value="Logout" variant="contained" size="large" aria-label="Sign Up" color="primary" endIcon={<PersonAddIcon />}>Sign Up</Button>
                
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Button onClick={signInWithGoogle} value="sign Up with Google" variant="contained" size="large" aria-label="Sign Up with Google" color="primary" endIcon={<GoogleIcon />}>Sign Up with Google</Button>
                <Typography>Please don't forget to choose a username afterwards so that your game data is tracked and so other players can find you.</Typography>                                                
            </TabPanel>
            </Box>                                                                      
            
            <p>
                Already have an account?{' '}
                <NavLink to="/login" state={{ path: pathBack }}>
                    <Button value="Login" variant="outlined" size="small" aria-label="Login" color="primary" endIcon={<PersonIcon />}>Login</Button>
                </NavLink>
            </p>                   
        </div>
    )
}
