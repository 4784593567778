import React from 'react';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Update() {
 
    return (
        <div className="body privacy-policy update">
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography><InfoIcon/>New GHB added. Welcome to Andtor!</Typography>
                </AccordionSummary>
                <AccordionDetails>
                        <ol>
                            <li>GHB version dropdown added to /new-game under Matched Play.</li>
                            <li>GHB 2023/2024 Missions Added.</li>
                            <li>GHB 2023/2024 Battle Tactics Added.</li>
                            <li>GHB 2023/2024 Grand Strategies Added.</li>
                        </ol>
                </AccordionDetails>
            </Accordion>

            <Divider />

        </div>
    );
}