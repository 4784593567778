import React, { useState, useEffect } from 'react';
import { doc, getDoc, getDocs, collection, where, query } from "firebase/firestore"; 
import { db } from '../../firebase';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Typography } from '@mui/material';
import { Legend } from './Legend';
// import { Alliance, Faction, SubFaction } from './Buttons';

export function UserStats(un) {

    // console.log('UserStats Props => ', un);
    // Game Data
    // Using a state allows us to fetch this on mount and show a loaded beforehand
    const [gameData, setGameData] = useState([]);
    const [dataReady, setDataReady] = useState(false);
    const [userData, setUserData] = useState([]);
    const [allianceList, setAllianceList] = useState([]);
    const [factionList, setFactionList] = useState([]);

    // console.log('UserStats => gameData => ', gameData);
    // console.log('UserStats => Userdata => ', userData);
    
    // console.log('allianceList => ', allianceList);
    // console.log('factionList => ', factionList);

    useEffect(() => {
        let timer1 = setTimeout(() => {
            fetchGameData();
            fetchUserData();
        }, 500);

        return () => {
            clearTimeout(timer1);
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    let content;
    let buildList = [];
    // console.log('UserStats => buildList => ', buildList);
    let allianceArray = []

    const fetchUserData = async () => {
        const docRef = doc(db, "usernames", un.un, "games/Matched Play");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserData(docSnap.data());
         
        }
      }

    const fetchGameData = async () => {

        const q = query(collection(db, "usernames", un.un, "games"), where("gameType", "==", "Matched Play"));
        console.log('UserStats => fetchGameData q => ', q);

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log("UserStats => fetchGameData q => ", doc.id, " => ", doc.data());
        });

        const gamesSnapshots = await getDocs(collection(db, "usernames", un.un, "games"), where("gameType", "==", "Matched Play"));
        gamesSnapshots.forEach((doc) => {
            // Use a standard array to organise the data
            // console.log('fetchGameData => allianceSnapshot: ', doc.id, Object.entries(doc.id));

            // console.log('UserStats => fetchGameData doc.id => ', doc.id);

            let newArray = []
            newArray.push(doc.id, [doc.data()]);

            // console.log('UserStats => fetchGameData => ', newArray);

            // setGameData(newArray);
        });

        const allianceSnapshot = await getDocs(collection(db, "usernames", un.un, "games/Matched Play/Faction"));
        allianceSnapshot.forEach((doc) => {
            // Use a standard array to organise the data
            // console.log('fetchGameData => allianceSnapshot: ', doc.id, Object.entries(doc.id));
            allianceArray.push(doc.id);
            setAllianceList(allianceArray);
        });
        
        const factionSnapshot = await getDocs(collection(db, "usernames", un.un, "games/Matched Play/Faction"));
        factionSnapshot.forEach((doc) => {
            // Use a standard array to organise the data
            // console.log('fetchGameData => factionSnapshot doc.data(): ', doc.data());
            setFactionList(Object.entries(doc.data()).filter(obj => obj[0].charAt(0) !== obj[0].charAt(0).toLowerCase()));
            // console.log('fetchGameData => factionSnapshot factionList: ', factionList);
            // console.log('fetchGameData => usefulBuildList: ', usefulBuildList);
        });

    }

    if (dataReady) {
        content = (
            <React.Fragment>
                <TableRow>
                    <TableCell>
                        { userData.wins ? userData.wins : 0 }
                    </TableCell>
                    <TableCell>
                        { userData.losses ? userData.losses : 0 }
                    </TableCell>
                    <TableCell>
                        { userData.draws ? userData.draws : 0 }
                    </TableCell>
                    <TableCell>
                        { userData.gamesPlayed ? ((userData.wins / userData.gamesPlayed).toFixed(2) * 100) + '%' : 0 }
                    </TableCell>
                    <TableCell>
                        { userData.gamesPlayed ? (userData.turnsCompleted / userData.gamesPlayed).toFixed(1) : 0 }
                    </TableCell>
                    <TableCell>
                        { userData.gamesPlayed ? userData.gamesPlayed : 0 }
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    } else {
        content = (
            <>
                <TableRow>
                    <TableCell className='fetching-data'>
                        Fetching Data <CircularProgress />
                    </TableCell>
                </TableRow>
            </>
        );
    }

    return (
        <Box className="stats-form user-stats" component={Paper} elevation={3}>                                   
            <TableContainer sx={{ minHeight: 320 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={6}>
                                <Typography>
                                    Performance: 
                                    <Button value="Overall" variant="contained" size="small" aria-label="Overall" color="primary" className="user-stats-filter">Overall</Button> 
                                </Typography>
                                {/* <Typography>
                                    By army:
                                    {dataReady ? <Alliance obj={allianceList} /> : ''}
                                    {dataReady ? <Faction obj={factionList} /> : ''}
                                    {dataReady ? <SubFaction obj={factionList} /> : ''}
                                </Typography>
                                <Typography>
                                    By mission:
                                </Typography> */}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                W
                            </TableCell>
                            <TableCell>
                                L
                            </TableCell>
                            <TableCell>
                                D
                            </TableCell>
                            <TableCell>
                                Win Rate
                            </TableCell>
                            <TableCell>
                                TC*
                            </TableCell>
                            <TableCell>
                                P±
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className='stats-body'>
                        {content}
                    </TableBody>
                </Table>
            </TableContainer>
            <Legend/>
        </Box>
    )
}

