import React from "react";
import { useFormContext } from "react-hook-form";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BattleTactic from "./BattleTactic";
import Achieved from "./Achieved";
import Input from "./Input";


const Turn = ({ player, turn, season, uurl, defaultData }) => {
// Define initial variables, with default option for form
const { register } = useFormContext();
    return (
        <TableRow
        key={turn}
        className={"turn-" + turn}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell className="turn"><p>{turn}</p></TableCell>
            <TableCell className="tactic" colSpan={1}><BattleTactic player={player} turn={turn} season={season} uurl={uurl} defaultData={defaultData} /><span>Achieved: <Achieved player={player} turn={turn} uurl={uurl} defaultData={defaultData}/></span></TableCell>
            <TableCell size="small" align="center">
                <Input player={player} turn={turn} value="VPs" totalScore={0} defaultData={defaultData} uurl={uurl}/>
            </TableCell>
        </TableRow>
    )

}
export default Turn;

