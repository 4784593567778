import React from "react";
import { useStickyState } from "../../utils/useStickyState";
import { useFormContext } from "react-hook-form";
import { arrayPrune } from "../../utils/arrayPrune";
import DefineLabel from "../../utils/DefineLabel";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import SubFaction from "./SubFaction";
import data from '../../../data/aos.json';

// Define the player name props so that we can assign "Player 1 / 2" when we import the component in App.js and apply this to the Label
export default function Faction({ player, alliance }) {
    // Prune the factions list based on the choice in the previous Armies Form
    const selectedAlliance = arrayPrune(data.grandAlliances, alliance);
    // Define initial variables, with default option for form
    const [faction, setFaction] = useStickyState('', player + ' Faction');
    // Move to own component soon
    // const [gStrats, setStrats] = useStickyState('', player + ' Grand Strategy');
    const [usableSubfactions, setSubFactions] = useStickyState('', player + ' Sub Factions');
    const [sft, setSubFactionsTitle] = useStickyState('', player + ' Sub Faction Title');

    // Function required to bubble values from this form input up to the parent form in Register.js
    const { register } = useFormContext();
    // When the form element changes, we update the value in stickyState
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      // Collapse faction to state that is matchable to JSON array (no caps, no spaces)
      let selectedFactionAsString = event.target.value.replace(/\s+/g,'').toLowerCase();
      setFaction(event.target.value);
      setSubFactions(selectedAlliance.factions[selectedFactionAsString].subFactions);
      setSubFactionsTitle(selectedAlliance.factions[selectedFactionAsString].subFactionTitle);
      // setStrats([...selectedAlliance.factions[selectedFactionAsString].grandStrategies, ...data.grandStrategies]);
    };
  
    let content;
    if (alliance) {
        content = (
            <Box
                component="div"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
            <div>
                <TextField
                  id="standard-select-faction1"
                  select
                  variant="standard"
                  name={player}
                  label={<DefineLabel player={player} parent='Faction.js' />}
                  value={faction}
                  required={true}
                  helperText="Select Faction"
                  {...register(player + ' Faction', {
                    shouldUnregister: true,
                    onChange: handleChange
                  })}
                >
                {Object.entries(selectedAlliance.factions).map(option => (
                    <MenuItem key={option[1].name} value={option[1].name}>
                        {option[1].name}
                    </MenuItem>
                ))}
              </TextField>, 
              <SubFaction player={player} alliance={alliance} faction={faction} subFaction={usableSubfactions} subFactionTitle={sft} />
              {/* <GrandStrategy player={player} alliance={alliance} faction={faction} grandStrats={gStrats} /> */}
            </div>
          </Box>
        );
    } 
    return <div>{content}</div>;
}
