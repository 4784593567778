// Delete me when you delete data console log. 
import React from "react";
import { useFormContext } from "react-hook-form";
import { useStickyState } from "../utils/useStickyState";
import { db } from "../../firebase";
import { setDoc, doc } from "firebase/firestore"; 
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import data from '../../data/aos.json';
import { arrayPrune } from "../utils/arrayPrune";

const BattleTactic = ({ player, turn, season, uurl, defaultData }) => {
    // Todos: Update Turn and Player to not require this change in every consuming component. 
    let playerName = player.replace(/\s+/g,'').toLowerCase();
    let turnEdit = "t" + turn;

    const playerFaction = defaultData[playerName].faction;
    const playerGrandAlliance = defaultData[playerName].alliance;
    let selectedFactionAsString = playerFaction.replace(/\s+/g,'').toLowerCase();
    let availableBattleTactics = arrayPrune(data.grandAlliances, playerGrandAlliance).factions[selectedFactionAsString].battleTactics;

    // Define initial variables, with default option for form
    const [bTactics, setTactics] = useStickyState('', player + ' Battle Tactic ' + turn);
    
    // Function required to bubble values from this form input up to the parent form in Register.js.
    const { register } = useFormContext();
    // When the form element changes, we update the value in stickyState
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTactics(event.target.value);
        updateTactic(event.target.value);
    };

    // Set the tactic in the database to preserve it on page refresh.
    const updateTactic = async (e) => {
      await setDoc(doc(db, `games/${uurl}`), {
          [playerName]: {
            battletactics: {
              [turnEdit]: {
                  chosen: e,
              }
            },
          },
      }, { merge: true });

      // Set the tactic in defaultData to manage state in this component. 
      defaultData[playerName].battletactics[turnEdit].chosen = e;
    }


    return (
        <>
        <Box
            component="div"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '30ch' },
            }}
            size="small"
            noValidate
            autoComplete="off"
            >
            <div className="tactics">
              <TextField
                id="standard-select-battletactics"
                select
                variant="standard"
                name={player}
                value={bTactics}
                {...register(player + ' Battle Tactic turn ' + turn, {
                  onChange: handleChange
                })}
              >
                <p>Faction Specific</p>
                {Object.keys(availableBattleTactics).map((option, i) => (
                    <MenuItem key={i} value={availableBattleTactics[option]}>
                        {availableBattleTactics[option]}
                    </MenuItem>
                ))}
                <p>Generals Handbook</p>
                {Object.keys(data.season[season][0].battleTactics).map((option, i) => (
                    <MenuItem key={i} value={data.season[season][0].battleTactics[option]}>
                        {data.season[season][0].battleTactics[option]}
                    </MenuItem>
                ))}
              </TextField>
            </div>
          </Box>
        </>
    )

}
export default BattleTactic;

