import React, { useState, useEffect } from "react";
import { useStickyState } from "../utils/useStickyState"
import { useLocalStorage } from "../utils/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { sendData } from "../utils/SendData";
import { useForm, FormProvider } from "react-hook-form";
import { db } from "../../firebase";
import { setDoc, doc, getDoc, updateDoc } from "firebase/firestore"; 
import Button from '@mui/material/Button';
import Scoresheet from "./Scoresheet";
import ScoresheetOpen from "./ScoresheetOpen";
import TurnNumber from "./TurnNumber";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ShareIcon from '@mui/icons-material/Share';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import StarsIcon from '@mui/icons-material/Stars';
import moment from 'moment';

// Set up tabs
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function Scoresheets(uniqueUrl) {
    const uniqueUrlTrimmed = uniqueUrl.uniqueUrl.replace('/game', '');
    const date = moment().format('L');

    const navigate = useNavigate();
    // Define form methods
    const methods = useForm();
    const [scoresSubmitted, setFormData] = useState(false);

    // Define empty array for data retrieval
    const [defaultData, setDefaultData] = useState({});
    const [dataReady, setDataReady] = useState(false);

    const [player1Score, setPlayer1Score] = useStickyState("", "Player 1 Score");
    const [player2Score, setPlayer2Score] = useStickyState("", "Player 2 Score");

    // fetchPlayerData to retrieve all fields from the `DATE_uniqueURLTrimmed` database. 
    // This replaces the `useLocalStorage` method which does not work on iOS. 
    useEffect(() => {
        checkRegisteredUsers1();
        checkRegisteredUsers2();
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const [gameEnded, setGameEnded] = useState(false);
    const [winner, setWinner] = useStickyState("", "Winner");
    const [winnerAlliance, setWinnerAlliance] = useStickyState("", "Winner Alliance");
    const [winnerFaction, setWinnerFaction] = useStickyState("", "Winner Faction");
    const [winnerSubFaction, setWinnerSubFaction] = useStickyState("", "Winner SubFaction");

    const [loser, setLoser] = useStickyState("", "Loser");
    const [loserAlliance, setLoserAlliance] = useStickyState("", "Loser Alliance");
    const [loserFaction, setLoserFaction] = useStickyState("", "Loser Faction");
    const [loserSubFaction, setLoserSubFaction] = useStickyState("", "Loser SubFaction");

    const [region, setRegion] = useState('');

    // Track turn number
    let [turnNumber, setTurnNumber] = useStickyState(1, 'Current Turn');
    // Track Priority
    const [t1P, setT1P] = useStickyState('', 'Turn 1 Priority');
    const [t2P, setT2P] = useStickyState('', 'Turn 2 Priority');
    const [t3P, setT3P] = useStickyState('', 'Turn 3 Priority');
    const [t4P, setT4P] = useStickyState('', 'Turn 4 Priority');
    const [t5P, setT5P] = useStickyState('', 'Turn 5 Priority');
    // TODOs Remove or build in double turn counter
    const [priorityHolder, setPriorityHolder] = useStickyState('', 'Priority Holder');

    const defaultDataArray = {
        complete: false, 
        gameType: useLocalStorage("Game Type", "")[0],
        loser: loser,
        losingAlliance: loserAlliance,
        losingFaction: loserFaction,
        losingSubFaction: loserSubFaction,
        mission: useLocalStorage("Mission", "")[0],
        points: useLocalStorage("Points", "")[0],
        region: "None",
        season: useLocalStorage("Season", "")[0],
        timestamp: date,
        turnEndedOn: turnNumber,
        uniqueId: uniqueUrlTrimmed,
        winner: winner,
        winningAlliance: winnerAlliance,
        winningFaction: winnerFaction,
        winningSubFaction: winnerSubFaction,
        player1: {
            alliance: useLocalStorage("Player 1 Grand Alliance", "")[0],
            faction: useLocalStorage("Player 1 Faction", "")[0],
            subfaction: useLocalStorage("Player 1 Chosen Sub Faction", "")[0],
            subfactionTitle: useLocalStorage("Player 1 Sub Faction Title", "")[0],
            name: useLocalStorage("Player 1 Name", "")[0],
            battletactics: {
                t1: {
                    chosen: useLocalStorage("Player 1 Battle Tactic 1", "")[0],
                    achieved: useLocalStorage("Player 1 Battle Tactic 1 Achieved", "")[0],
                },
                t2: {
                    chosen: useLocalStorage("Player 1 Battle Tactic 2", "")[0],
                    achieved: useLocalStorage("Player 1 Battle Tactic 2 Achieved", "")[0],
                },
                t3: {
                    chosen: useLocalStorage("Player 1 Battle Tactic 3", "")[0],
                    achieved: useLocalStorage("Player 1 Battle Tactic 3 Achieved", "")[0],
                },
                t4: {
                    chosen: useLocalStorage("Player 1 Battle Tactic 4", "")[0],
                    achieved: useLocalStorage("Player 1 Battle Tactic 4 Achieved", "")[0],
                },
                t5: {
                    chosen: useLocalStorage("Player 1 Battle Tactic 5", "")[0],
                    achieved: useLocalStorage("Player 1 Battle Tactic 5 Achieved", "")[0],
                },
            },
            grandstrategy: {
                chosen: useLocalStorage("Player 1 Grand Strategy", "")[0],
                achieved: useLocalStorage("Player 1 Grand Strategy Achieved", "")[0],
            },
            score: {
                t1: useLocalStorage("Player 1 Score t1", "")[0],
                t2: useLocalStorage("Player 1 Score t2", "")[0],
                t3: useLocalStorage("Player 1 Score t3", "")[0],
                t4: useLocalStorage("Player 1 Score t4", "")[0],
                t5: useLocalStorage("Player 1 Score t5", "")[0],
                total: player1Score,
            }
        },
        player2: {
            alliance: useLocalStorage("Player 2 Grand Alliance", "")[0],
            faction: useLocalStorage("Player 2 Faction", "")[0],
            subfaction: useLocalStorage("Player 2 Chosen Sub Faction", "")[0],
            subfactionTitle: useLocalStorage("Player 2 Sub Faction Title", "")[0],
            name: useLocalStorage("Player 2 Name", "")[0],
            battletactics: {
                t1: {
                    chosen: useLocalStorage("Player 2 Battle Tactic 1", "")[0],
                    achieved: useLocalStorage("Player 2 Battle Tactic 1 Achieved", "")[0],
                },
                t2: {
                    chosen: useLocalStorage("Player 2 Battle Tactic 2", "")[0],
                    achieved: useLocalStorage("Player 2 Battle Tactic 2 Achieved", "")[0],
                },
                t3: {
                    chosen: useLocalStorage("Player 2 Battle Tactic 3", "")[0],
                    achieved: useLocalStorage("Player 2 Battle Tactic 3 Achieved", "")[0],
                },
                t4: {
                    chosen: useLocalStorage("Player 2 Battle Tactic 4", "")[0],
                    achieved: useLocalStorage("Player 2 Battle Tactic 4 Achieved", "")[0],
                },
                t5: {
                    chosen: useLocalStorage("Player 2 Battle Tactic 5", "")[0],
                    achieved: useLocalStorage("Player 2 Battle Tactic 5 Achieved", "")[0],
                },
            },
            grandstrategy: {
                chosen: useLocalStorage("Player 2 Grand Strategy", "")[0],
                achieved: useLocalStorage("Player 2 Grand Strategy Achieved", "")[0],
            },
            score: {
                t1: useLocalStorage("Player 2 Score t1", "")[0],
                t2: useLocalStorage("Player 2 Score t2", "")[0],
                t3: useLocalStorage("Player 2 Score t3", "")[0],
                t4: useLocalStorage("Player 2 Score t4", "")[0],
                t5: useLocalStorage("Player 2 Score t5", "")[0],
                total: player2Score,
            }
        },
        priority: {
            t1: t1P,
            t2: t2P,
            t3: t3P,
            t4: t4P,
            t5: t5P
        }
    }

    // DEFINE PLAYER 1 Name
    const player1Name = useLocalStorage("Player 1 Name", "")[0];
    // DEFINE PLAYER 2 Name
    const player2Name = useLocalStorage("Player 2 Name", "")[0];
    // DEFINE PLAYER 1 Alliance
    const player1Alliance = useLocalStorage("Player 1 Grand Alliance", "")[0];
    // DEFINE PLAYER 2 Alliance
    const player2Alliance = useLocalStorage("Player 2 Grand Alliance", "")[0];
    // DEFINE PLAYER 1 Faction
    const player1Faction = useLocalStorage("Player 1 Faction", "")[0];
    // DEFINE PLAYER 2 Faction
    const player2Faction = useLocalStorage("Player 2 Faction", "")[0];
    // DEFINE PLAYER 1 Faction
    const player1SubFaction = useLocalStorage("Player 1 Chosen Sub Faction", "")[0];
    // DEFINE PLAYER 2 Faction
    const player2SubFaction = useLocalStorage("Player 2 Chosen Sub Faction", "")[0];
    // DEFINE PLAYER 1 Faction Title
    const player1SubFactionTitle = useLocalStorage("Player 1 Sub Faction Title", "")[0];
    // DEFINE PLAYER 2 Faction Title
    const player2SubFactionTitle = useLocalStorage("Player 2 Sub Faction Title", "")[0];

    const fetchData = () => {
        setDefaultData(defaultDataArray);
        setDataReady(true);
        return init();
    }

    const init = () => {
        console.log('Scoresheets.JS => Data Ready: ', dataReady);
        if (dataReady) {
            // On page load set the tab to move to tab 2. This prevents errors onSubmit where no data is found for that stateless form. 
            // TODOS: Find a better way
            setValue(1);
            if (!priorityHolder) {
                handleOpenPriority();
            }

            reserveDatabase();
        } else {
            // console.log('Init dataReady False', dataReady);
        }
    }

    // See if Players are logged in users or not
    const [p1Registered, setP1Registered] = useState(false);
    const [p2Registered, setP2Registered] = useState(false);

    const turnClass = 'button-turn-';

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    // Modal Functions
    const [open, setOpen] = React.useState(false);
    const [openPriority, setOpenPriority] = React.useState(false);
    const [openEndTurn, setOpenEndTurn] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpenEndTurn = () => setOpenEndTurn(true);
    const handleCloseEndTurn = () => setOpenEndTurn(false);
    const handleOpenPriority = () => setOpenPriority(true);
    const handleClosePriority = () => setOpenPriority(false);

    // TODOS Componentise
    const checkRegisteredUsers1 = async () => {
        // Check if Player 1 is a Registered user
        const p1Ref = doc(db, "usernames", player1Name);
        const p1Snap = await getDoc(p1Ref);
        if (p1Snap.exists()) {
            setP1Registered(true);
            setRegion(p1Snap.data().country);
            defaultData.region = region ? region : "None";
        } 
    }

    const checkRegisteredUsers2 = async () => {
        // Check if Player 2 is a Registered user
        const p2Ref = doc(db, "usernames", player2Name);
        const p2Snap = await getDoc(p2Ref);
        if (p2Snap.exists()) {
            setP2Registered(true);
        } 
    }

    // Reserve the database with known information on component mount
    const reserveDatabase = async () => {
        const docRef = doc(db, "games", uniqueUrlTrimmed);
        const docSnap = await getDoc(docRef);
        // If the game already exists, don't overwrite it. 
        if (docSnap.exists()) {
            return;
        } else {
            // Create the new game
            await setDoc(doc(db, `games/${uniqueUrlTrimmed}`), {
                complete: gameEnded, 
                gameType: defaultData.gameType,
                mission: defaultData.mission,
                points: defaultData.points,
                region: defaultData.region,
                season: defaultData.season,
                timestamp: defaultData.timestamp,
                turnEndedOn: defaultData.turnEndedOn,
                uniqueId: defaultData.uniqueId,
                player1Username: p1Registered ? defaultData.player1.name : "None",
                player2Username: p2Registered ? defaultData.player2.name : "None",
                player1: {
                    alliance: defaultData.player1.alliance,
                    faction: defaultData.player1.faction,
                    subfaction: defaultData.player1.subfaction,
                    subfactionTitle: defaultData.player1.subfactionTitle,
                    name: defaultData.player1.name,
                    username: p1Registered ? defaultData.player1.name : "None",
                    battletactics: {
                        t1: {
                            chosen: "None",
                            achieved: false
                        },
                        t2: {
                            chosen: "None",
                            achieved: false
                        },
                        t3: {
                            chosen: "None",
                            achieved: false
                        },
                        t4: {
                            chosen: "None",
                            achieved: false
                        },
                        t5: {
                            chosen: "None",
                            achieved: false
                        },
                    },
                    grandstrategy: {
                        chosen: "None",
                        achieved: false,
                    },
                    score: {
                        t1: defaultData.player1.score.t1,
                        t2: defaultData.player1.score.t2,
                        t3: defaultData.player1.score.t3,
                        t4: defaultData.player1.score.t4,
                        t5: defaultData.player1.score.t5,
                        total: player1Score,
                    }
                },
                player2: {
                    alliance: defaultData.player2.alliance,
                    faction: defaultData.player2.faction,
                    subfaction: defaultData.player2.subfaction,
                    subfactionTitle: defaultData.player2.subfactionTitle,
                    name: defaultData.player2.name,
                    username: p2Registered ? defaultData.player2.name : "None",
                    battletactics: {
                        t1: {
                            chosen: "None",
                            achieved: false
                        },
                        t2: {
                            chosen: "None",
                            achieved: false
                        },
                        t3: {
                            chosen: "None",
                            achieved: false
                        },
                        t4: {
                            chosen: "None",
                            achieved: false
                        },
                        t5: {
                            chosen: "None",
                            achieved: false
                        },
                    },
                    grandstrategy: {
                        chosen: "None",
                        achieved: false,
                    },
                    score: {
                        t1: defaultData.player2.score.t1,
                        t2: defaultData.player2.score.t2,
                        t3: defaultData.player2.score.t3,
                        t4: defaultData.player2.score.t4,
                        t5: defaultData.player2.score.t5,
                        total: player2Score,
                    }
                },
                priority: {
                    t1: t1P,
                    t2: t2P,
                    t3: t3P,
                    t4: t4P,
                    t5: t5P,
                }
            });
        }
    }

    
    const onSubmit = async (data) => {
        // Update Turn
        setFormData(true);
        let player1TotalArray = [
            Number(defaultData.player1.score.t1),
            Number(defaultData.player1.score.t2),
            Number(defaultData.player1.score.t3),
            Number(defaultData.player1.score.t4),
            Number(defaultData.player1.score.t5),
        ]

        let player2TotalArray = [
            Number(defaultData.player2.score.t1),
            Number(defaultData.player2.score.t2),
            Number(defaultData.player2.score.t3),
            Number(defaultData.player2.score.t4),
            Number(defaultData.player2.score.t5),
        ]

        // Getting sum of numbers
        var p1sum = player1TotalArray.reduce(function(a, b){
            // If one form fields is undefined, set the value to 0. 
            return a + (b || 0);
        }, 0);   
        
        if (defaultData.gameType === "Matched Play") {
            if (defaultData.player1.grandstrategy.achieved) {
                setPlayer1Score(p1sum + 3);
    
                // Update score in Game Data Array
                defaultData.player1.score.total = p1sum + 3;
            } else {
                setPlayer1Score(p1sum);
    
                // Update score in Game Data Array
                defaultData.player1.score.total = p1sum
            } 
        } else {
            // Narrative or Open Play Detected. No Grand Strat Needed. 
            // Update score in Game Data Array
            defaultData.player1.score.total = p1sum
            setPlayer1Score(p1sum);
        } 

        // Getting sum of numbers
        var p2sum = player2TotalArray.reduce(function(a, b){
            // If one form fields is undefined, set the value to 0. 
            return a + (b || 0);
        }, 0);


        if (defaultData.gameType === "Matched Play") {
            if (defaultData.player2.grandstrategy.achieved) {
                setPlayer2Score(p2sum + 3);
                // Update score in Game Data Array
                defaultData.player2.score.total = p2sum + 3;
            } else {
                setPlayer2Score(p2sum);
                // Update score in Game Data Array
                defaultData.player2.score.total = p2sum;
            }
        } else {
            // Narrative or Open Play Detected. No Grand Strat Needed. 
            // Update score in Game Data Array
            defaultData.player2.score.total = p2sum
            setPlayer2Score(p2sum);
        }

        if ((p1sum > p2sum) && (p1sum !== p2sum)) {
            setWinner(defaultData.player1.name);
            setWinnerAlliance(defaultData.player1.alliance);
            setWinnerFaction(defaultData.player1.faction);
            setWinnerSubFaction(defaultData.player1.subfaction);
            setLoser(defaultData.player2.name);
            setLoserAlliance(defaultData.player2.alliance);
            setLoserFaction(defaultData.player2.faction);
            setLoserSubFaction(defaultData.player2.subfaction);
        } else if ((p2sum > p1sum) && (p1sum !== p2sum)) {
            setWinner(defaultData.player2.name);
            setWinnerAlliance(defaultData.player2.alliance);
            setWinnerFaction(defaultData.player2.faction);
            setWinnerSubFaction(defaultData.player2.subfaction);
            setLoser(defaultData.player1.name);
            setLoserAlliance(defaultData.player1.alliance);
            setLoserFaction(defaultData.player1.faction);
            setLoserSubFaction(defaultData.player1.subfaction);
        } else {
            setWinner('none');
            defaultData.winner = 'draw';
        }  

        await setDoc(doc(db, `games/${uniqueUrlTrimmed}`), {
            player1Username: p1Registered ? defaultData.player1.name : "None",
            player2Username: p2Registered ? defaultData.player2.name : "None",
            player1: {
                score: {
                    t1: defaultData.player1.score.t1,
                    t2: defaultData.player1.score.t2,
                    t3: defaultData.player1.score.t3,
                    t4: defaultData.player1.score.t4,
                    t5: defaultData.player1.score.t5,
                    total: player1Score,
                }
            },
            player2: {
                score: {
                    t1: defaultData.player2.score.t1,
                    t2: defaultData.player2.score.t2,
                    t3: defaultData.player2.score.t3,
                    t4: defaultData.player2.score.t4,
                    t5: defaultData.player2.score.t5,
                    total: player2Score,
                }
            },
            complete: gameEnded,
            gameType: defaultData.gameType,
            mission: defaultData.mission,
            season: defaultData.season,
            timestamp: defaultData.timestamp,
            region: region ? region : "None",
            turnEndedOn: turnNumber,
            priority: {
                t1: t1P,
                t2: t2P,
                t3: t3P,
                t4: t4P,
                t5: t5P,
            },
            loser: loser,
            losingAlliance: loserAlliance,
            losingFaction: loserFaction,
            losingSubFaction: loserSubFaction,
            winner: winner,
            winningAlliance: winnerAlliance,
            winningFaction: winnerFaction,
            winningSubFaction: winnerSubFaction,
        }, { merge: true });

    }  

    function handleStartPriority(e) {
        setT1P(e.target.value);
        setPriorityHolder(e.target.value);
        handleClosePriority();
    }

    function handlePriority(e) {
        if (turnNumber === 1) {
            // As we are ending Turn 1, setting priority for next turn
            setT2P(e.target.value);
            setPriorityHolder(e.target.value);
            defaultData.priority.t2 = e.target.value;
        } else if (turnNumber === 2) {
            // As we are ending Turn 2, setting priority for next turn
            setT3P(e.target.value);
            setPriorityHolder(e.target.value);
            defaultData.priority.t3 = e.target.value;
        } else if (turnNumber === 3) {
            // As we are ending Turn 3, setting priority for next turn
            setT4P(e.target.value);
            setPriorityHolder(e.target.value);
            defaultData.priority.t4 = e.target.value;
        } else if (turnNumber === 4) {
            // As we are ending Turn 4, setting priority for next turn
            setT5P(e.target.value);
            setPriorityHolder(e.target.value);
            defaultData.priority.t5 = e.target.value;
        } else if (turnNumber === 5) {
            console.log('Priority Turn => ', turnNumber, ' ending game');
        }

        advanceTurn();
        handleCloseEndTurn();
    }

    function advanceTurn() {
        // I'm doing this as: setTurnNumber(turnNumber++); takes two clicks to actually update and i'm bored of trying to fix it. 
        if (turnNumber === 1) {
            setTurnNumber(2);
        } else if (turnNumber === 2) {
            setTurnNumber(3);
        } else if (turnNumber === 3) {
            setTurnNumber(4);
        } else if (turnNumber === 4) {
            setTurnNumber(5);
        } else if (turnNumber === 5) {
            setTurnNumber(5);
        }
    }

    let shareAble;
    if (dataReady) {
        shareAble = defaultData.player1.name + ' VS ' + defaultData.player2.name + '. ' 
        + defaultData.player1.faction + ' VS ' + defaultData.player2.faction + '. '
        + 'Scores: ' + player1Score + ' - ' + player2Score + '. '
        + 'Battle Round: ' + turnNumber + '. '
        + 'Tracked with www.scorehammer.co.uk';
    } else {
        shareAble = 'null';
    }

    function shareContent() {
        navigator.clipboard.writeText(shareAble);
    }

    // TODOS: turn into a util
    const endGame = async () => {
        console.log("END GAME");
        // setGameEnded(true);
        dataUpdate();
    }

    const navEnd = () => {
        navigate("/ended", {
            state: {
                data: defaultData,
                uniqueUrl: uniqueUrlTrimmed,
            }
        });

        // localStorage.clear();
    }

    const dataUpdate = async () => {
        // Submitting Overall Data
        defaultData.region = region;
        defaultData.date = date;
        defaultData.complete = gameEnded;
        defaultData.turnEndedOn = turnNumber;

        console.log('Game Ended, Sending Data: ', defaultData);
        console.log('SendData.js => Sending Data: ', p1Registered, player1Name);
        

        sendData(defaultData, defaultData.player1.name, p1Registered, defaultData.player2.name, p2Registered, false, uniqueUrlTrimmed);

        // Take me to a game ended screen
        navEnd();
    }

    const dataUpdateFake = async () => {
        // Submitting Overall Data
        defaultData.region = region;
        defaultData.date = date;
        defaultData.complete = gameEnded;
        defaultData.turnEndedOn = turnNumber;

        sendData(defaultData, defaultData.player1.name, p1Registered, defaultData.player2.name, p2Registered, true, uniqueUrlTrimmed);

        console.log('Game Ended, Sending Data: ', defaultData);

        // Take me to a game ended screen
        navEnd();
    }

    let classes = `scoresheets scoresheets-${turnNumber}`;

    // When the data isn't ready we return a shell until the data is ready and the values are updated
    if (dataReady) {
        return (
            <>
                <div className="scoresheet-vs-header">
                    <div className="p1-header player-header">
                            <div className="fade"></div>
                            <h3>{defaultData.player1.name}</h3>
                            <p>{defaultData.player1.faction}</p>
                    </div>
                    <div className="vs"><h2>Vs</h2></div>
                    <div className="p2-header player-header">
                            <div className="fade"></div>
                            <h3>{defaultData.player2.name}</h3>
                            <p>{defaultData.player2.faction}</p>
                    </div>
                </div>
                <p>{date}</p>
                <div className="turn-number"><TurnNumber currentTurn={turnNumber} /></div>
                <div className="scores"><p className="p1-score">{player1Score ? player1Score : 0}</p> - <p className="p2-score">{player2Score ? player2Score : 0}</p></div>
                {/* <Button type="submit" onClick={dataUpdate} variant="outlined" size="large" aria-label="Send Data" id="send-data">Send Data</Button>
                <Button type="submit" onClick={dataUpdateFake} variant="outlined" size="large" aria-label="Send Data" id="send-data">Send Data Fake</Button> */}
                <FormProvider {...methods} className={gameEnded ? 'over' : 'in-progress'} > 
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className={classes}>
                            <div className="selectors">
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} centered onChange={handleChange} aria-label="basic tabs example" className="tabs-wrapper">
                                            <Tab label={defaultData.player1.name} iconPosition="end" icon={priorityHolder === defaultData.player1.name ? <StarsIcon /> : ""} {...a11yProps(0)} />
                                            <Tab label={defaultData.player2.name} iconPosition="end" icon={priorityHolder === defaultData.player2.name ? <StarsIcon /> : ""} {...a11yProps(1)} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0} className={gameEnded ? 'tab-panel over' : 'in-progress'} component="div">
                                        {defaultData.gameType === 'Matched Play' ? <Scoresheet player="Player 1" season={defaultData.season} uurl={uniqueUrlTrimmed} defaultData={defaultData} /> : <ScoresheetOpen player="Player 1" uurl={uniqueUrlTrimmed} defaultData={defaultData} />}
                                        <div className="scoresheet-footer">
                                            <div>
                                                <Button type="submit" disabled={gameEnded ? false : false} variant="outlined" size="medium" aria-label="Update Scores" id="update-scores" endIcon={<ArrowCircleUpIcon />}>Update Scores</Button>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={1} className={gameEnded ? 'tab-panel over' : 'in-progress'}  component="div">
                                    {defaultData.gameType === 'Matched Play' ? <Scoresheet player="Player 2" season={defaultData.season} uurl={uniqueUrlTrimmed} defaultData={defaultData} /> : <ScoresheetOpen player="Player 2" uurl={uniqueUrlTrimmed} defaultData={defaultData} />}
                                        <div className="scoresheet-footer">
                                            <div>
                                                <Button type="submit" disabled={gameEnded ? false : false} variant="outlined" size="medium" aria-label="Update Scores" id="update-scores" endIcon={<ArrowCircleUpIcon />}>Update Scores</Button>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Box>
                            </div>
                            <div className="end-turn">  
                                <div>                
                                    <Button type="submit" disabled={gameEnded ? true : false} onClick={handleOpenEndTurn} variant="contained" size="large" aria-label="End Turn" id="end-turn" className={turnClass+turnNumber} endIcon={<DoneOutlineIcon />}>End Battle Round {turnNumber}</Button>
                                </div>
                            </div>
                        </div>

                        <div className="end-game">
                            <Button type="submit" onClick={handleOpen} disabled={gameEnded ? false : false} value="Submit" variant="contained" size="large" aria-label="End Game" color="success" endIcon={<DoneAllIcon />}>End Game & Submit Data</Button>
                        </div>

                        <div className="share-scores">
                            <Button onClick={shareContent} value="Share" variant="outlined" size="large" aria-label="Share Scores" color="primary" endIcon={<ShareIcon />}>Share Scores</Button>
                        </div>

                        {/* End Game Modal */}
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box className="end-game-modal">
                                <div className="turn-number"><TurnNumber currentTurn={turnNumber} /></div>
                                <div className="scores"><p className="p1-score">{player1Score ? player1Score : 0}</p> - <p className="p2-score">{player2Score ? player2Score : 0}</p></div>
                                <Divider variant="middle" className="modal-divider" />
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Are you ready to end the game?
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    By ending the game you will not be able to update scores.
                                </Typography>
                                <Button type="submit" onClick={endGame} disabled={gameEnded ? true : false} value="Submit" variant="contained" size="large" aria-label="End Game" color="success" endIcon={<DoneAllIcon />}>End Game</Button>
                            </Box>
                        </Modal>

                        {/* Begin Game Modal */}
                        <Modal
                            open={openPriority}
                            onClose={handleClosePriority}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box className="end-turn-modal">
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Who has priority in Battle Round 1?
                                </Typography>
                                <Button type="submit" disabled={gameEnded ? true : false} onClick={handleStartPriority} value={defaultData.player1.name} variant="outlined" size="large" aria-label="End Turn" id="end-turn" className={turnClass+turnNumber}>{defaultData.player1.name}</Button>
                                <Button type="submit" disabled={gameEnded ? true : false} onClick={handleStartPriority} value={defaultData.player2.name} variant="outlined" size="large" aria-label="End Turn" id="end-turn" className={turnClass+turnNumber}>{defaultData.player2.name}</Button>
                            </Box>
                        </Modal>

                        {/* End Turn Modal */}
                        <Modal
                            open={openEndTurn}
                            onClose={handleCloseEndTurn}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box className="end-turn-modal">
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Who has priority in Battle Round {turnNumber + 1}?
                                </Typography>
                                <Button type="submit" disabled={gameEnded ? true : false} onClick={handlePriority} value={defaultData.player1.name} variant="outlined" size="large" aria-label="End Turn" id="end-turn" className={turnClass+turnNumber}>{defaultData.player1.name}</Button>
                                <Button type="submit" disabled={gameEnded ? true : false} onClick={handlePriority} value={defaultData.player2.name} variant="outlined" size="large" aria-label="End Turn" id="end-turn" className={turnClass+turnNumber}>{defaultData.player2.name}</Button>
                            </Box>
                        </Modal>
                </form>
                </FormProvider>
            </>
        );

    } else {
        return (
            <>
                <div className="scoresheet-vs-header">
                    <div className="p1-header player-header">
                            <div className="fade"></div>
                            <h3>Loading Player Name</h3>
                            <p>Loading Player Army</p>
                    </div>
                    <div className="vs"><h2>Vs</h2></div>
                    <div className="p2-header player-header">
                            <div className="fade"></div>
                            <h3>Loading Player Name</h3>
                            <p>Loading Player Army</p>
                    </div>
                </div>
                <p>{date}</p>
                <div className="turn-number"><TurnNumber currentTurn={turnNumber} /></div>
                <div className="scores"><p className="p1-score">{player1Score ? player1Score : 0}</p> - <p className="p2-score">{player2Score ? player2Score : 0}</p></div>
                {/* <Button type="submit" onClick={dataUpdate} variant="outlined" size="large" aria-label="Send Data" id="send-data">Send Data</Button> */}
                <FormProvider {...methods} className={gameEnded ? 'over' : 'in-progress'} > 
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className={classes}>
                            <div className="selectors">
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} centered onChange={handleChange} aria-label="basic tabs example" className="tabs-wrapper">
                                            <Tab label={"Player 1"} iconPosition="end" icon={priorityHolder === "player 1" ? <StarsIcon /> : ""} {...a11yProps(0)} />
                                            <Tab label={"Player 2"} iconPosition="end" icon={priorityHolder === "player 2" ? <StarsIcon /> : ""} {...a11yProps(1)} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0} className={gameEnded ? 'tab-panel over' : 'in-progress'} component="div">

                                    </TabPanel>
                                    <TabPanel value={value} index={1} className={gameEnded ? 'tab-panel over' : 'in-progress'}  component="div">
    
                                    </TabPanel>
                                </Box>
                            </div>
                            <div className="end-turn">  
                                <div>                
                                    <Button type="submit" disabled={gameEnded ? true : false} onClick={handleOpenEndTurn} variant="contained" size="large" aria-label="End Turn" id="end-turn" className={turnClass+turnNumber} endIcon={<DoneOutlineIcon />}>End Battle Round {turnNumber}</Button>
                                </div>
                            </div>
                        </div>

                        <div className="end-game">
                            <Button type="submit" onClick={handleOpen} disabled={gameEnded ? false : false} value="Submit" variant="contained" size="large" aria-label="End Game" color="success" endIcon={<DoneAllIcon />}>End Game & Submit Data</Button>
                        </div>

                        <div className="share-scores">
                            <Button onClick={shareContent} value="Share" variant="outlined" size="large" aria-label="Share Scores" color="primary" endIcon={<ShareIcon />}>Share Scores</Button>
                        </div>

                        
                </form>
                </FormProvider>
            </>
        );
    }
}